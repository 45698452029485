class VendorDto {
    constructor(name, ownerEmail, products, address, active){
        this.name = name;
        this.ownerEmail = ownerEmail;
        this.products = products;
        this.address = address;
        this.active = active;
    }
}


export default VendorDto;
// {"name":"COMICSTOREmalwieder","ownerEmail":"erik.buerge@test8.com","products":[],"address":{"id":55,"town":"Aalen","zipCode":1059,"road":"eyostreet","houseNumber":"23141","country":"AF","latitude":48.835973,"longitude":10.08991},"active":false}