import './SignUpPage.css'

import {Row, Col, Form, Button} from 'react-bootstrap';
import {toast} from 'react-toastify';
import sendApiRequest from '../../services/httpRequestor.js'
import RegisterUserDto from '../../services/dto/RegisterUserDto.js'
import validatePassword from "../../services/validatePassword";
import {login} from "../loginPage/loginPage";
import LoginInfoDto from "../../services/dto/LoginInfoDto";


function SignUpPage() {
    return(
        <div>

            <div>
                <div className="Heading">
                    <h1 style={{margin: '2em'}}>Registrieren</h1>
                </div>
            </div>
            <div className="registration-container">
                <Form onSubmit={handleSubmit} id="registrationForm">
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>Vorname*</Form.Label>
                            <Form.Control id="regFirstName" placeholder="Vorname eingeben" required/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>*Nachname</Form.Label>
                            <Form.Control id="regLastName" placeholder="Nachname eingeben" required/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Email*</Form.Label>
                            <Form.Control id="regEmail" type="email" placeholder="Email eingeben" required/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col}>
                            <Form.Label>*Passwort</Form.Label>
                            <Form.Control id="regPassword" type="password" placeholder="Passwort eingeben" required/>
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label style={{ visibility: 'hidden' }}>Passwort wiederholen</Form.Label>
                            <Form.Control id="regPasswordRepeat" type="password" placeholder="Passwort wiederholen" required/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Col className="text-end">
                            <Button class="btn-primary" type="submit">
                                Registrieren
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <br />
                <small>*Pflichtpfeld</small>
            </div>

</div>
);

    function checkPassword(){
        const pw = document.getElementById("regPassword");
        const pwConfirm = document.getElementById("regPasswordRepeat");

        if (pw.value === pwConfirm.value){
            if (validatePassword(pw.value)){
                //toast.success("Passwort gültig.")
                return true;
            } else {
                toast.error("Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Ziffer und ein Sonderzeichen enthalten.")
            }
        } else {
            toast.error("Passwörter stimmen nicht überein.");
            return false;
        }
    }

    async function handleSubmit(event) {
        event.preventDefault();

        if (checkPassword()) {
            const email = document.getElementById("regEmail").value;
            const firstName = document.getElementById("regFirstName").value;
            const lastName = document.getElementById("regLastName").value;
            const pw = document.getElementById("regPassword").value;

            let regUserDto = new RegisterUserDto(email, lastName, firstName, pw);
            //alert(regUserDto.email + regUserDto.name + regUserDto.firstname + regUserDto.password);
            const res = await registerUser(regUserDto);
            console.log(res);
            if (res.status === 200) {
                toast.success("Registrierung erfolgreich!");
                await login(new LoginInfoDto(email, pw));
            } else if (res.status === 409){
                toast.error("Account mit E-Mail existiert bereits.")
            }
            else {
                toast.error("Registrierung fehlgeschlagen!");
            }
        }
    }

}

//regisrierungs Funktion
async function registerUser(regUserDto) {
    let response = await sendApiRequest('/user/register', regUserDto);
    return response;

    //alert(response.status);

}

export default SignUpPage;