import './VendorProducts.css'
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {baseUri} from "../../services/httpRequestor";
import VendorDto from "../../services/dto/VendorDto";
import Address from "../../services/classes/Address";
import isValidFloat from "../../services/isValidFloat";
import isValidInteger from "../../services/isValidInteger";

function VendorProducts(){
    // localStorage Checks
    const PUpdateSuccess = localStorage.getItem("PUpdateSuccess");
    if (PUpdateSuccess){
        toast.success(PUpdateSuccess);
        localStorage.removeItem("PUpdateSuccess");
    }
    const PCreateSuccess = localStorage.getItem("PCreateSuccess");
    if (PCreateSuccess) {
        toast.success(PCreateSuccess);
        localStorage.removeItem("PCreateSuccess");
    }
    const PDeleteSuccess = localStorage.getItem("PDeleteSuccess");
    if (PDeleteSuccess) {
        toast.success(PDeleteSuccess);
        localStorage.removeItem("PDeleteSuccess");
    }

    //Zustandshook für userInfo DTO
    let [vendorInfo, setVendorInfo] = useState(null);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    let token = window.localStorage.getItem("token");

    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + token,
        },
    }

    const uriVendor = baseUri.concat("/vendor/getInternals/");

    const getVendorInfo = async () => {
        const responseUser = await fetch(uriVendor, requestOptions);
        //console.log(requestOptions);
        //console.log(response);
        if (responseUser.status === 404) {
            localStorage.setItem('noVendor', 'Der Account besitzt kein zugehöriges Geschäft.')
            window.location.href = '/profile'
        } else if (responseUser.status === 200) {
            const stream = responseUser.body.getReader();
            const reader = await stream.read();
            const text = new TextDecoder().decode(reader?.value);
            console.log(text);
            const value = JSON.parse(text);
            setVendorInfo(value);
            await getVendorProducts(value.name);
            console.log(vendorInfo);
        } else {
            toast.error("Failed to fetch Vendor Info")
        }
    };

    const uriVendorProducts = baseUri.concat("/products/");

    const getVendorProducts = async (vendorName) => {
        const responseVendorProducts = await fetch(uriVendorProducts + vendorName, requestOptions);

        if (responseVendorProducts.status === 200){
            const vpStream = responseVendorProducts.body.getReader();
            const vpReader = await vpStream.read();
            const vpText = new TextDecoder().decode(vpReader?.value);
            const vpValue = JSON.parse(vpText);
            setProducts(vpValue);
            setLoading(false);
            console.log(vpValue);
        }

    }

    useEffect(() => {
        getVendorInfo();
    }, [uriVendor]);

    //------------------------Login required - Handling------------------------------
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
        localStorage.setItem("loginRequired", "Bitte loggen Sie sich ein.");
        window.location.href = "/login";
        return false;
    }
    //-------------------------------------------------------------------------------

    function RenderProductCard(id, name, description, imageUris="", price, stock){
        return (
            <div id="productCard" className="card customCardCSS mb-2">
                <div className="row g-0">
                    <div className="col-md-6">
                        <div className="card-body">
                            <h5 className="card-title">{name}</h5>
                            <p className="card-text"><a href="#" className="text-decoration-none">(ID-{id})</a></p>
                            <span
                                onClick={() => deleteProduct(id)} // Aufruf der deleteProduct-Funktion mit der Produkt-ID
                                style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    color: '#d63b28',
                                    verticalAlign: 'bottom'
                                }}>Löschen</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card-body">
                            <p className="card-text">Beschreibung: {description}</p>
                            {/*<p className="card-text">Bilder: {imageUris}</p>*/}
                            <p className="card-text">Preis: {price} €</p>
                            <p className="card-text">Stock: {stock}</p>
                            <span
                                onClick={() => togglePopup(id, name, description, imageUris, price, stock)}
                                style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    color: 'blue',
                                    marginRight: '5px'
                                }}>Bearbeiten</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function togglePopup(id, name, description, imageUris="", price, stock) {
        const overlay = document.getElementById('popupOverlay');
        const nameInput = document.getElementById('productName');
        const idInput = document.getElementById('productId');
        const descInput = document.getElementById('productDesc');
        const imageUrisInput = document.getElementById('productImageUris');
        const priceInput = document.getElementById('productPrice');
        const stockInput = document.getElementById('productStock');

        nameInput.value = name;
        idInput.value = id;
        descInput.value = description;
        //imageUrisInput.value = imageUris;
        priceInput.value = price;
        stockInput.value = stock;

        overlay.classList.toggle('show');
    }

    async function productConfirm(event){
        event.preventDefault();

        const idInput = document.getElementById('productId');
        const nameInput = document.getElementById('productName');
        const descInput = document.getElementById('productDesc');
        // const imageUrisInput = document.getElementById('productImageUris');
        const priceInput = document.getElementById('productPrice');
        const stockInput = document.getElementById('productStock');

        if (!isValidInteger(stockInput.value)) {
            toast.error("Stock muss eine Ganzzahl sein.");
            return false;
        }
        if (!isValidFloat(priceInput.value)) {
            toast.error("Preis muss eine Dezimalzahl sein. (Hinweis: Komma durch \".\")");
            return false;
        }

        if (!nameInput.value || !descInput.value || /*!imageUrisInput.value ||*/ !priceInput.value || !stockInput.value) {
            toast.error("Daten unvollständig.");
            return false;
        } else {
            if (idInput.value && idInput.value.trim() !== ""){
                // Producte Update
                const requestOptionsPUpdate = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer " + token,
                    },
                    body: JSON.stringify({
                        "id": idInput.value,
                        "name": nameInput.value,
                        "description": descInput.value,
                        "imageUris": [
                        ""
                    ],
                        "price": priceInput.value,
                        "stock": stockInput.value
                    })
                };
                console.log(requestOptionsPUpdate);
                const responsePUpdate = await fetch(baseUri.concat("/product/update"), requestOptionsPUpdate);
                console.log(responsePUpdate);
                if (responsePUpdate.status === 200){
                    localStorage.setItem("PUpdateSuccess", "Produktdaten für \"" + nameInput.value + "\" erfolgreich übermittelt.");
                    window.location.reload();
                } else {
                    toast.error("Fehler bei Übermittlung der Produktdaten.")
                }
            } else {
                // Product Create
                const requestOptionsPCreate = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer " + token,
                    },
                    body: JSON.stringify({
                        "name": nameInput.value,
                        "description": descInput.value,
                        "imageUris": [
                            ""
                        ],
                        "price": priceInput.value,
                        "stock": stockInput.value
                    })
                };
                console.log(requestOptionsPCreate);
                const responsePCreate = await fetch(baseUri.concat("/product/create"), requestOptionsPCreate);
                console.log(responsePCreate);
                if (responsePCreate.status === 200){
                    localStorage.setItem("PCreateSuccess", "Produktdaten für \"" + nameInput.value + "\" erfolgreich übermittelt.");
                    window.location.reload();
                } else {
                    toast.error("Fehler bei Übermittlung der Produktdaten.")
                }
                }
            }
            //toast.success("Produktdaten für \"" + nameInput.value + "\" erfolgreich übermittelt.")
            return true;
        }

    const deleteProduct = async (productId) => {
        const requestOptionsPDelete = {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + token,
            },
        }
        console.log(requestOptionsPDelete);
        const responsePDelete = await fetch(baseUri.concat("/product/" + productId));
        console.log(responsePDelete);

        if (responsePDelete.status === 200){
            localStorage.setItem("PDeleteSuccess", "Produkt (id = " + productId + ") erfolgreich gelöscht.");
            window.location.reload();
        } else if (responsePDelete.status === 404) {
            toast.error("Fehler: Produkt nicht gefunden.")
        } else {
            toast.error("Fehler bei Datenübertragung");
        }

        console.log("Produkt mit der ID", productId, "wird gelöscht.");
    };


//------------------------------------------------------------------------
    var productCards = products.map(a => {
        return  RenderProductCard(a.id, a.name, a.description, a.imageUris, a.price, a.stock);
    })

//id, type, name, streetNameNumber, zip, city, country, paketstationNum

//for (const a in address) {
//    console.log("Address : " + a + " - " + typeof(a));
//    if (a == 0) {continue;}
//    addressCards.push(RenderAddressCard(a.id, "", (a.address.road + " " + a.address.houseNumber ), a.address.zipCode, a.address.town, a.address.country, a.poNumber));
//}

    return (
        <div style={{margin: '2em'}}>
            <div className="Heading">
                <h1 style={{margin: '2em'}}>Produktverwaltung</h1>
            </div>
            <div className="container">
                <center>
                    {products && !loading && products.length > 0 ? (
                        (productCards)
                    ) : products && !loading && products.length === 0 ? (
                        <p>Keine Produkte verfügbar.</p>
                    ) : (
                        <p>Loading...</p>
                    )}
                    <a onClick={() => togglePopup("", "", "", "", "", "")}>
                        <div id="addProductCard" className="text-center card customCardCSS mb-2">
                            <div className="row g-0">
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <h5 className="card-title">Produkt hinzufügen</h5>
                                        <p className="card-text"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </center>

            </div>

            <div id="popupOverlay" className="overlay-container">
                <div className="popup-box" id="popup-box-address">
                    <h2>Produkt anlegen/ändern</h2>
                    <form className="form-container" onSubmit={productConfirm}>
                        <label className="form-label" htmlFor="name">
                            Produktname
                        </label>
                        <input className="form-input" type="text" id="productName" placeholder="Name" required/>
                        <input className="form-input" type="text" id="productId" placeholder="ID (automatisch vergeben)" required disabled/>


                        <label className="form-label">Produktbeschreibung</label>
                        <input className="form-input" id="productDesc" type="text" placeholder="Beschreibung" required/>
                        {/*<input className="form-input" id="productImageUris" type="text" placeholder="ImageUris (List)" required/>*/}
                        <input className="form-input" id="productPrice" type="text" placeholder="Preis" required/>
                        <input className="form-input" id="productStock" type="text" placeholder="Stock"
                               required/>

                        <button id='productConfirm' className="btn-submit-product" type="submit">
                            Übermitteln
                        </button>
                    </form>

                    <button className="btn-close-popup-product" onClick={togglePopup}>
                        Abbrechen
                    </button>
                </div>
            </div>


        </div>
    );

}

export default VendorProducts;