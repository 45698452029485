import React, { useState } from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { getCart,removeFromCart, clearCart } from '../../services/cookieCart';

function Cart() {
    //--------------------------------------------------------------------
    //Handling und UseState
    const [cartItems, setCartItems] = useState(getCart());

    const handleChangeQuantity = (productId, newQuantity) => {
        if (newQuantity <= 0) {
            handleRemoveFromCart(productId);
        } else {
            setCartItems(prevItems =>
                prevItems.map(item =>
                    item.id === productId ? { ...item, quantity: newQuantity } : item
                )
            );
            const updatedCart = cartItems.map(item =>
                item.id === productId ? { ...item, quantity: newQuantity } : item
            );
            localStorage.setItem('cart', JSON.stringify(updatedCart));
        }
    };

    const handleRemoveFromCart = (productId) => {
        setCartItems(prevItems =>
            prevItems.filter(item => item.id !== productId)
        );
        removeFromCart(productId);
    };

    const handleClearCart = () => {
        setCartItems([]);
        clearCart();
    };
    //--------------------------------------------------------------------
    // Wenn Warenkorb leer:
    if (cartItems.length <= 0) {
        return (
            <div style={{margin: '2em'}}>
                <div className="Heading">
                    <h1 style={{margin: '2em', marginBottom: '1em'}}>Dein Warenkorb ist leer.</h1>
                </div>
                <p class="text-center">Zum Shop <a href="/marketplace">hier</a>!</p>
            </div>
        )
    } else {
    //--------------------------------------------------------------------
    // Wenn Warenkorb befüllt:
    return (
        <div style={{margin: '2em'}}>
            <div className="Heading">
                <h1 style={{margin: '2em', marginBottom: '1em'}}>Dein Warenkorb</h1>
            </div>
            <Container style={{paddingBottom: '10%', paddingLeft: '10%', paddingRight: '10%'}}>
                {cartItems.map(item => (
                    <Card key={item.id} className="customCardCSS mb-4">
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Card.Title>{item.name}</Card.Title>
                                    <Card.Text>Beschreibung: {item.description}</Card.Text>
                                    <Card.Text>Preis: {item.price} €</Card.Text>
                                    <Card.Text>
                                        Menge:
                                        <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            className="ms-2"
                                            onClick={() => handleChangeQuantity(item.id, item.quantity - 1)}
                                        >
                                            -
                                        </Button>
                                        <span className="mx-2">{item.quantity}</span>
                                        <Button
                                            variant="outline-secondary"
                                            size="sm"
                                            className="me-2"
                                            onClick={() => handleChangeQuantity(item.id, item.quantity + 1)}
                                            disabled={item.quantity >= item.stock}
                                        >
                                            +
                                        </Button>
                                    </Card.Text>
                                </Col>
                                <Col className="d-lg-flex justify-content-center">
                                    <Button variant="outline-danger" onClick={() => handleRemoveFromCart(item.id)}
                                            onClick={() => handleRemoveFromCart(item.id)}>Entfernen</Button>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                ))}
                <Row className="justify-content-between">
                    <Col>
                        <Button variant="danger" onClick={handleClearCart} className="me-3 w-100">Warenkorb leeren</Button>
                    </Col>
                    <Col>
                        <Button variant="success" className="w-100" href='/checkout'>Zur Kasse</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}}

export default Cart;
