import './VendorProfile.css';
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import {baseUri} from "../../services/httpRequestor";


function VendorProfile() {

    let isVendor = localStorage.getItem("isVendor");
    if (isVendor !== "true"){
        localStorage.setItem('noVendor', 'Der Account besitzt kein zugehöriges Geschäft.')
        window.location.href = '/profile'
    }

    let UpdateVenInfSuccess = localStorage.getItem("UpdateVenInfSuccess");
    if (UpdateVenInfSuccess){
        toast.success(UpdateVenInfSuccess);
        localStorage.removeItem("UpdateVenInfSuccess");
    }
    //------------------------User Handling------------------------------
    // Zustandshook für das userInfo DTO
    let [userInfo, setUserInfo] = useState(null);

    // Funktion zum Abrufen der Benutzerinformationen
    const getInfo = async () => {
        const token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + token,
            },
        };
        const uri = baseUri.concat("/user/get");

        try {
            const response = await fetch(uri, requestOptions);
            if (response.status === 200) {
                const data = await response.json();
                setUserInfo(data);
                //console.log(data)
            } else {
                toast.error("Failed to fetch User Info");
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
            toast.error("Failed to fetch User Info");
        }
    };

    // Effekthook zum Laden der Benutzerinformationen beim Rendern der Komponente
    useEffect(() => {
        getInfo();
    }, []);

    if (userInfo && !userInfo.vendor) {
        localStorage.setItem('noVendor', 'Der Account besitzt kein zugehöriges Geschäft.')
        window.location.href = '/profile'
    }
    //------------------------Vendor Handling------------------------------
    let [vendorInfo, setVendorInfo] = useState(null);

    const checkStatus = (statusProducts) => {
        if (statusProducts === 200) {
            return true;
        } else {
            return false;
        }
    }

    //------------------------Login required - Handling------------------------------
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
        localStorage.setItem("loginRequired", "Bitte loggen Sie sich ein.");
        window.location.href = "/login";
        return false;
    }
    //-------------------------------------------------------------------------------

    return (
        <div style={{margin: '2em'}}>
            <div className="Heading">
                <h1 style={{margin: '2em'}}>Geschäft verwalten</h1>
            </div>

            {userInfo && (userInfo.vendor != null) ? (
                <div>
                <h5 className="text-center">Geschäftsname: {userInfo.vendor}</h5>
            <h5 className="text-center">Besitzer*in: {userInfo.firstName} {userInfo.name}</h5>
                    </div>
            ) : (
            <p>Loading...</p>
            )}<br/>


            <h3 className="text-center">Optionen</h3><br/>
            <div className="row row-cols-1 row-cols-md-3 g-4">
                <div className="col">
                    <div className="card customCardCSS h-100">
                        <div className="card-body">
                            <h5 className="card-title">Geschäftsinformationen</h5>
                            <p className="card-text">
                                Name und Geschäftsanschrift
                            </p>
                        </div>
                        <div className="card-footer">
                            <a href="/VendorInfo" className="btn btn-light" role="button">Anzeigen</a>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card customCardCSS h-100">
                        <div className="card-body">
                            <h5 className="card-title">Produktverwaltung</h5>
                            <p className="card-text">Produkte hinzufügen, löschen oder bearbeiten</p>
                        </div>
                        <div className="card-footer">
                            <a href="/VendorProducts" className="btn btn-light" role="button">Anzeigen</a>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card customCardCSS h-100">
                        <div className="card-body">
                            <h5 className="card-title">Aufträge</h5>
                            <p className="card-text">Aufträge einsehen</p>
                        </div>
                        <div className="card-footer">
                            <a href="/VendorTasks" className="btn btn-light" role="button">Anzeigen</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default VendorProfile;