import './RegisterVendor.css'
import {Button, Col, Form, Row} from "react-bootstrap";
import ReactFlagsSelect from "react-flags-select";
import {useState, useEffect} from "react";
import {baseUri} from "../../services/httpRequestor";
import {toast} from "react-toastify";

function RegisterVendor() {
    // Zustandshook für das userInfo DTO
    let [userInfo, setUserInfo] = useState(null);
    const [selected, setSelected] = useState("");

    // Funktion zum Abrufen der Benutzerinformationen
    const getInfo = async () => {
        const token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + token,
            },
        };
        const uri = baseUri.concat("/user/get");

        try {
            const response = await fetch(uri, requestOptions);
            if (response.status === 200) {
                const data = await response.json();
                setUserInfo(data);
                if (data.address && data.address.country){
                    setSelected(data.address.country);
                } else {
                    setSelected("");
                }
                //console.log(data)
            } else {
                toast.error("Failed to fetch User Info");
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
            toast.error("Failed to fetch User Info (Error catch)");
        }
    };

    // Effekthook zum Laden der Benutzerinformationen beim Rendern der Komponente
    useEffect(() => {
        getInfo();
    }, []);

    //------------------------Toast Fehlermeldungen Handling SET------------------------------
    if (userInfo && userInfo.vendor) {
        localStorage.setItem('alreadyVendor', 'Der Account besitzt bereits ein zugehöriges Geschäft.')
        window.location.href = '/profile'
    }

    let isLoggedIn = window.localStorage.getItem("isLoggedIn");
    if (isLoggedIn == null || isLoggedIn === "false") {
        localStorage.setItem('LoginForVendorReg', 'Sie müssen eingeloggt sein, um ein Geschäft zu registrieren.')
        window.location.href = '/login'
    }

    return (
        <div>

            <div>
                <div className="Heading">
                    <h1 style={{margin: '2em'}}>Geschäft registrieren</h1>
                </div>
            </div>
            <div className="registration-container">
                {userInfo ? (
                    <Form onSubmit={handleSubmit} id="registrationForm">
                        <Row className="mb-3">
                            <h5>Persönliche Daten</h5>
                            <Form.Group as={Col}>
                                <Form.Label>Vorname</Form.Label>
                                <Form.Control defaultValue={userInfo.firstName} id="regFirstName"
                                              placeholder="Vorname eingeben" required disabled/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Nachname</Form.Label>
                                <Form.Control defaultValue={userInfo.name} id="regLastName"
                                              placeholder="Nachname eingeben" required disabled/>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>E-Mail</Form.Label>
                                <Form.Control defaultValue={userInfo.email} id="regEmail" type="email"
                                              placeholder="Email eingeben" required disabled/>
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <h5>Geschäftsdaten</h5>
                            <Form.Group as={Col} controlId="formGridVendorName">
                                <Form.Label>Geschäftsname</Form.Label>
                                <Form.Control placeholder="Geschäftsname" required></Form.Control>
                            </Form.Group>
                        </Row>
                        <Row className="mb-2">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label>Straße</Form.Label>
                                    <Form.Control
                                        defaultValue={userInfo == null ? "" : (userInfo.address == null ? "" : userInfo.address.road)}
                                        placeholder="Straße" required/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridAddress2">
                                    <Form.Label>Hausnummer</Form.Label>
                                    <Form.Control
                                        defaultValue={userInfo == null ? "" : (userInfo.address == null ? "" : userInfo.address.houseNumber)}
                                        placeholder="Hausnummer" required/>
                                </Form.Group>
                        </Row>

                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>Stadt*</Form.Label>
                                <Form.Control
                                    defaultValue={userInfo == null ? "" : (userInfo.address == null ? "" : userInfo.address.town)}
                                    placeholder="Stadt" required/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridZip">
                                <Form.Label>PLZ*</Form.Label>
                                <Form.Control
                                    defaultValue={userInfo == null ? "" : (userInfo.address == null ? "" : userInfo.address.zipCode)}
                                    placeholder="PLZ" required/>
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>Staat*</Form.Label>
                                <ReactFlagsSelect selected={selected} onSelect={(code) => setSelected(code)}
                                                  required/>

                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Col className="text-end">
                                <Button class="btn-primary" type="submit">
                                    Geschäft registrieren
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    // Zeige einen Ladeindikator oder eine Nachricht während des Ladens an
                    <p>Loading...</p>
                )}
                <br/>
                <small>*Pflichtpfeld</small>
            </div>

        </div>
    );

    async function handleSubmit(event) {
        event.preventDefault();

        const ownerEmail = userInfo.email;
        const name = event.target.elements.formGridVendorName.value;
        const town = event.target.elements.formGridCity.value;
        const zipCode = event.target.elements.formGridZip.value;
        const road = event.target.elements.formGridAddress1.value;
        const houseNumber = event.target.elements.formGridAddress2.value;
        const country = selected;

        const token = window.localStorage.getItem("token");
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + token,
            },
            body: JSON.stringify({
                "ownerEmail": ownerEmail,
                "name": name,
                "address": {
                    "town": town,
                    "zipCode": zipCode,
                    "road": road,
                    "houseNumber": houseNumber,
                    "country": country,
                    "latitude": 0,
                    "longitude": 0
                }
            })
        }
        console.log(requestOptions);
        const response = await fetch(baseUri.concat("/vendor/create"), requestOptions);
        console.log(response.status);
        if (response.status === 200){
            console.log(response)
            localStorage.setItem("isVendor", "true");
            localStorage.setItem("vendorRegSuccess", "Geschäft erfolgreich registriert!")
            window.location.href = '/profile';
        } else if (response.status === 409) {
            localStorage.setItem("isVendor", "false");
            toast.error("Geschäftsname existiert bereits.");
        } else {
            toast.error("Fehler bei Geschäftsregistrierung.")
        }

}
}

export default RegisterVendor;