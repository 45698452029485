

function validatePassword(password) {
    let complexityRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\\|[\]{};:'",.<>/?]).{8,}$/;

    // password length and complexity check
    if (password.length < 8) {
        return false;
    } else if (!complexityRegex.test(password)){
        return false;
    }
    // validation complete
    return true;
}

export default validatePassword;