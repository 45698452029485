import './VendorInfo.css';
import { useState, useEffect } from 'react';
import {baseUri} from "../../services/httpRequestor";
import {Button, Col, Form, Row} from "react-bootstrap";
import ReactFlagsSelect from "react-flags-select";
import {toast} from "react-toastify";
import VendorDto from '../../services/dto/VendorDto';
import Address from '../../services/classes/Address';
import containsEmptyString from "../../services/containsEmptyString";
import shallowEqual from "../../services/shallowEqual";

function VendorInfo() {

    let [vendorInfo, setVendorInfo] = useState(null)
    let [selectedFlag, setSelectedFlag] = useState(null);
    let [altVendorDto, setAltVendorDto] = useState(new VendorDto());
    let [vendorDto, setVendorDto] = useState(new VendorDto());
    let token = window.localStorage.getItem("token");

    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": "Bearer " + token,
        },
    }

    const uriVendor = baseUri.concat("/vendor/getInternals/");

    const getVendorInfo = async () => {
        const response = await fetch(uriVendor, requestOptions);
        //console.log(requestOptions);
        //console.log(response);
        if (response.status === 404) {
            localStorage.setItem('noVendor', 'Der Account besitzt kein zugehöriges Geschäft.')
            window.location.href = '/profile'
        } else if (response.status === 200) {
            const stream = response.body.getReader();
            const reader = await stream.read();
            const text = new TextDecoder().decode(reader?.value);
            console.log(text);
            const value = JSON.parse(text);
            setVendorInfo(value);
            if (value.address && value.address.country) {
                setSelectedFlag(value.address.country);
                const newVendorDto = new VendorDto(
                    value.name,
                    value.ownerEmail,
                    value.products,
                    new Address(
                        value.address.road,
                        value.address.houseNumber,
                        value.address.zipCode,
                        value.address.town,
                        value.address.country,
                        value.address.longitude,
                        value.address.latitude
                    ),
                    value.active);
                setVendorDto(newVendorDto);
                setAltVendorDto(newVendorDto);
            } else {
                setSelectedFlag("")
            }
            console.log(vendorInfo);
        } else {
            toast.error("Failed to fetch Vendor Info")
        }
    };

    useEffect(() => {
        getVendorInfo();
    }, [uriVendor]);

    useEffect(() => {
        console.log("VendorDto updated:", vendorDto);
    }, [vendorDto]);

    // Name Change
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setVendorDto(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Address Change except Country
    const handleAddressChange = (e) => {
        const { name, value } = e.target;
        setVendorDto(prevState => {
            // Kopiere das vorhandene Address-Objekt
            const updatedAddress = { ...prevState.address };
            // Aktualisiere das entsprechende Attribut basierend auf dem Namen des Feldes
            updatedAddress[name] = value;
            // Gib das aktualisierte VendorDto zurück, inklusive der aktualisierten Adresse
            return {
                ...prevState,
                address: updatedAddress
            };
        });
        console.log(VendorDto);
    };


    // Country Change
    const handleCountryChange = (countryCode) => {
        setVendorDto(prevState => ({
            ...prevState,
            address: {
                ...prevState.address,
                country: countryCode
            }
        }));
        console.log(vendorDto)
    };

    //------------------------Login required - Handling------------------------------
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
        localStorage.setItem("loginRequired", "Bitte loggen Sie sich ein.");
        window.location.href = "/login";
        return false;
    }
    //-------------------------------------------------------------------------------

    return (

        <>
            <div className="Heading">
                <h1 style={{margin: '2em'}}>Geschäftsinformationen</h1>
            </div>

            <div style={{marginRight: '20%', marginLeft: '20%'}}>
                {(vendorInfo && vendorInfo.name) ? (
                    <>
                        <Form noValidate id="formContainerPersonal" onSubmit={handleSubmit}>
                            <h5>Allgemeine Informationen</h5>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridEmail">
                                    <Form.Label>E-Mail</Form.Label>
                                    <Form.Control
                                        name="ownerEmail" // Namen des Eingabefeldes hinzufügen
                                        value={vendorDto.ownerEmail || ""} // Wert aus dem VendorDto verwenden
                                        placeholder="E-Mail" required disabled/>
                                </Form.Group>
                            </Row>
                            <p><a href="/personalInfo"><small>Persönliche Daten bearbeiten</small></a></p>

                            <h5>Geschäftliche Informationen</h5>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Geschäftsname*</Form.Label>
                                    <Form.Control
                                        name="name" // Namen des Eingabefeldes hinzufügen
                                        value={vendorDto.name || ""}
                                        placeholder="Geschäftsname" required
                                        onChange={handleInputChange}/> {/* onChange hinzufügen */}
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridAddress1">
                                    <Form.Label>Straße*</Form.Label>
                                    <Form.Control
                                        name="road" // Namen des Eingabefeldes hinzufügen
                                        value={vendorDto.address ? vendorDto.address.road || "" : ""}
                                        placeholder="Straße" required
                                        onChange={handleAddressChange}/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridAddress2">
                                    <Form.Label>Hausnummer*</Form.Label>
                                    <Form.Control
                                        name="houseNumber" // Namen des Eingabefeldes hinzufügen
                                        value={vendorDto.address ? vendorDto.address.houseNumber || "" : ""}
                                        placeholder="Hausnummer" required
                                        onChange={handleAddressChange}/>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>Stadt*</Form.Label>
                                    <Form.Control
                                        name="town" // Namen des Eingabefeldes hinzufügen
                                        value={vendorDto.address ? vendorDto.address.town || "" : ""}
                                        placeholder="Stadt" required
                                        onChange={handleAddressChange}/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridzipCode">
                                    <Form.Label>Postleitzahl*</Form.Label>
                                    <Form.Control
                                        name="zipCode" // Namen des Eingabefeldes hinzufügen
                                        value={vendorDto.address ? vendorDto.address.zipCode || "" : ""}
                                        placeholder="Postleitzahl" required
                                        onChange={handleAddressChange}/>
                                </Form.Group>

                                <Form.Group as={Col} controlId="formGridState">
                                    <Form.Label>Staat*</Form.Label>
                                    <ReactFlagsSelect
                                        selected={selectedFlag}
                                        onSelect={(code) => {
                                            setSelectedFlag(code);
                                            handleCountryChange(code);
                                        }}
                                        required/>
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                <Col className="text-end">
                                    <Button className="btn-primary" type="submit">
                                        Änderungen speichern
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <br/>
                        <p><small>* Pflichtfeld</small></p>
                    </>
                ) : (<p>Loading...</p>)}
            </div>
        </>
    )

    async function handleSubmit(event) {
        event.preventDefault();

        const submitVendorDto = vendorDto;
        if (!containsEmptyString(submitVendorDto)) {
            if (!shallowEqual(submitVendorDto, altVendorDto)) {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer " + token,
                    },
                    body: JSON.stringify(submitVendorDto)
                }
                console.log(requestOptions);
                const response = await fetch(baseUri.concat("/vendor/update"), requestOptions);
                if (response.status === 200) {
                    localStorage.setItem("UpdateVenInfSuccess", "Aktualisierung der Geschäftsinformationen erfolgreich.");
                    window.location.href = '/vendorProfile';
                }
                else {
                    toast.error("Fehler bei Aktualisierung der Geschäftsinformationen.")
            }
            } else {
                toast.error("Keine Änderung der Daten festgestellt.")
            }
        } else {
            toast.error('Bitte füllen Sie alle erforderlichen Felder aus.');
        }

    }
}

export default VendorInfo;