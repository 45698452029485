class payMethod{
    constructor(id, type, name, payInf1, payInf2) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.payInf1 = payInf1;
        this.payInf2 = payInf2;
    };

    getHiddenInf1() {
        let length1 = this.payInf1.length;
        let startIdx = Math.floor(length1 * 0.75);
        let hiddenPart = this.payInf1.substring(startIdx);
        let filledPart = "*".repeat(length1 - startIdx);
        return filledPart + hiddenPart;
    }

    getHiddenInf2() {
        let length2 = this.payInf2.length;
        let startIdx = Math.floor(length2 * 0.75);
        let hiddenPart = this.payInf2.substring(startIdx);
        let filledPart = "*".repeat(length2 - startIdx);
        return filledPart + hiddenPart;
    }
}

export default payMethod;