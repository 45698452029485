class Address {
    constructor(road, houseNumber, zipCode, town, country, latitude, longitude) {
        this.road = road;
        this.houseNumber = houseNumber;
        this.zipCode = zipCode;
        this.town = town;
        this.country = country;
        this.latitude = latitude;
        this.longitude = longitude;
    }

    get fullAddress() {
        return `${this.road} ${this.houseNumber}, ${this.zipCode} ${this.town}, ${this.country}`;
    }
}

export default Address;