import './DsgvoPopup.css'

function DsgvoPopup() {
    return (
        <div id="pu1" className="pu-overley">
            <div className="dsgvo-popup-box">
                <p>
                    Aus technischen Gründen werden Cookies gespeichert.   
                </p>
                <p>Durch das Bestätigen dieser Nachricht stimmen Sie unseren <a href="/agb">AGB</a> und <a href="/dataconsent">Datenschutzrichtlinien</a> zu.</p>
                
                    <button className="btn-submit" onClick={acceptPopup}>
                            Zustimmen
                    </button>
            </div>
        </div>
    );
}

function acceptPopup() {
    const element = document.getElementById('pu1');
    if(element != null) {
        localStorage.setItem('dsgvoAccept', "true");
        element.style.display = 'none'; 
    }
}

export default DsgvoPopup;