import './MyOrders.css'
import {baseUri} from "../../services/httpRequestor";
import {toast} from "react-toastify";
import { useState, useEffect } from "react";

function MyOrders(){
    let [orders, setOrders] = useState([]);
    let [loading, setLoading] = useState(true);

    const token = localStorage.getItem('token');

    const requestOptionsOrders = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization" : "Bearer " + token,
        },
    }

    const uriOrders = baseUri.concat("/order/list");
    const getOrders = async () => {
        const responseOrders = await fetch(uriOrders, requestOptionsOrders);

        if (responseOrders.status === 200) {
            const streamOrders = responseOrders.body.getReader();
            const readerOrders = await streamOrders.read();
            const textOrders = new TextDecoder().decode(readerOrders?.value);
            console.log(textOrders);
            const valueOrders = JSON.parse(textOrders);
            setOrders(valueOrders);
            setLoading(false);
        }
        else {
            toast.error("Failed to fetch User Info")
        }
    };

    useEffect(() =>
    {
        getOrders();
    }, [])

    //------------------------Login required - Handling------------------------------
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
        localStorage.setItem("loginRequired", "Bitte loggen Sie sich ein.");
        window.location.href = "/login";
        return false;
    }
    //-------------------------------------------------------------------------------
    
    function RenderOrderCard(order){
        return (
            <div className="container">
                <div id="orderCard" className="card customCardCSS mb-3">
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="card-body">
                                <h5 className="card-title">Bestellungs-Nr. {order.id}</h5>
                                {order.products.map(product => (
                                    <p key={product.id} className="card-text"><a href="#" className="text-decoration-none">{product.name}, Anzahl: {product.stock}, Preis: {product.price} €</a></p>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card-body">
                                <p className="card-text">Gesamtpreis: {order.total}</p>
                                <p className="card-text">Kaufdatum: {order.purchaseDate}</p>
                                <p className="card-text">Lieferdatum: {order.deliveryDate}</p>
                                <p className="card-text">Versandadresse: <a href="/myaddresses"
                                                                            className="text-decoration-none">{order.deliveryAddress.road} {order.deliveryAddress.houseNumber}, {order.deliveryAddress.zipCode} {order.deliveryAddress.town}</a>
                                </p>
                                <a href="#" className="btn btn-primary">Bestellung verfolgen</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    var orderCards = orders.map(order => RenderOrderCard(order));
  
    return (

        <div style={{margin: '2em'}}>

            <div className="Heading">
                <h1 style={{margin: '2em'}}>Meine Bestellungen</h1>
            </div>
            {loading ? (
                <p style={{ textAlign: 'center' }}>Loading...</p>
            ) : orders.length === 0 ? (
                <p style={{ textAlign: 'center' }}>Keine Bestellungen.</p>
            ) : (
                orderCards
            )}


        </div>

    )
}

export default MyOrders;