function AGB() {

    return (
        <div className="zentriert" style={{ margin: '2em' }}>
            <h2>AGB</h2>

            <p><strong>Allgemeine Geschäftsbedingungen (AGB) für die Nutzung von LocalPro:</strong></p>

            <p><strong>Geltungsbereich</strong></p>
            <p>1.1 Diese Allgemeinen Geschäftsbedingungen regeln die Nutzung von LocalPro (im Folgenden "Website") und sämtliche damit verbundenen Dienstleistungen und Angebote.</p>
            <p>1.2 Mit der Nutzung der Website erklären Sie sich mit diesen AGB einverstanden. Abweichende Bedingungen des Nutzers werden nicht anerkannt, es sei denn, dies wurde ausdrücklich schriftlich vereinbart.</p>

            <p><strong>Leistungen</strong></p>
            <p>2.1 Die Website bietet Nutzern die Möglichkeit, Produkte und Dienstleistungen zu erwerben.</p>
            <p>2.2 Die angebotenen Produkte und Dienstleistungen sowie deren Preise sind auf der Website ersichtlich.</p>
            <p>2.3 Die Website behält sich das Recht vor, das Angebot an Produkten und Dienstleistungen sowie die Preise jederzeit zu ändern oder einzustellen.</p>

            <p><strong>Registrierung</strong></p>
            <p>3.1 Zur Nutzung bestimmter Funktionen der Website ist eine Registrierung erforderlich.</p>
            <p>3.2 Die Registrierung erfolgt durch die Eingabe der erforderlichen Daten und die Bestätigung der AGB und der Datenschutzbestimmungen.</p>
            <p>3.3 Die Nutzer sind verpflichtet, ihre Zugangsdaten vertraulich zu behandeln und vor dem Zugriff durch Dritte zu schützen.</p>

            <p><strong>Bestellungen</strong></p>
            <p>4.1 Durch das Absenden einer Bestellung auf der Website gibt der Nutzer ein verbindliches Angebot zum Kauf der ausgewählten Produkte oder Dienstleistungen ab.</p>
            <p>4.2 Die Bestätigung des Eingangs der Bestellung erfolgt automatisch und stellt noch keine Vertragsannahme dar.</p>
            <p>4.3 Ein Vertrag kommt erst zustande, wenn die Website die Bestellung bestätigt oder die bestellten Produkte versendet.</p>

            {/* Weitere Punkte analog formatieren */}

            <p><strong>Schlussbestimmungen</strong></p>
            <p>10.1 Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.</p>
            <p>10.2 Gerichtsstand für alle Streitigkeiten aus oder im Zusammenhang mit diesen AGB und der Nutzung der Website ist [Ort des Unternehmens].</p>
            <p>10.3 Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.</p>

            <p>Diese Allgemeinen Geschäftsbedingungen können jederzeit von der Website geändert werden. Es gilt die jeweils aktuelle Fassung, die auf der Website veröffentlicht ist.</p>


        </div>
    );
}

export default AGB;
