//URL für API Requests 
export const baseUri = "https://api.localpro.kurt-brunner.de";

async function sendApiRequest(uriPath, body) {

    const requestOptions = {
        method: 'POST',
        headers: {  
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
        body: JSON.stringify(body)
    }

    const uri = baseUri.concat(uriPath);

    const response = await fetch(uri, requestOptions)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            alert("generiert von httpRequestor: " + "\n" + error); 
        });
        return response;
            
}

export default sendApiRequest;