import './Footer.css'
import { useState, useEffect } from 'react';
import {baseUri} from "../../../services/httpRequestor";
import { toast } from 'react-toastify';

function Footer() {
    //------------------------User Handling------------------------------
    let isLoggedIn = window.localStorage.getItem("isLoggedIn");
    let isVendor = window.localStorage.getItem("isVendor");
    console.log(isVendor);
    let token = window.localStorage.getItem("token");
    let [userInfo, setUserInfo] = useState(null);


    return (
            <footer id="footer">
                <div className="row" style={{verticalAlign: "middle"}}>
                    <div className="col">
                        <a href='/impressum'>Impressum</a>
                    </div>
                    <div className="col">
                        <a href='/dataconsent'>Datenschutzerklärung</a>
                    </div>
                    <div className="col">
                        <a href='/agb'>Allgemeine Geschäftsbedingungen </a>
                    </div>
                    <div className="col">
                        <a href='/registerVendor'>Händler werden</a>
                    </div>
                </div>
                <div className="row">
                    <div className="row">
                        <div className="col">
                            <a>© 2024</a>
                        </div>
                    </div>
                </div>
            </footer>

    );
}

export default Footer;
  