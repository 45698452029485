function DataConsent() {

    return (
        <div className="zentriert" style={{ margin: '2em' }}>
            <h2>Datenschutzerklärung</h2>

            <p><strong>Datenschutzerklärung:</strong></p>

            <p><strong>Verantwortliche Stelle</strong></p>
            <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze ist LocalPro, Musterweg, 12345 Stadt, vertreten durch Max Mustermann.</p>

            <p><strong>Erhebung und Verarbeitung personenbezogener Daten</strong></p>
            <p>2.1 Personenbezogene Daten werden nur erhoben, wenn Sie uns diese im Rahmen Ihrer Bestellung, bei Eröffnung eines Kundenkontos oder bei der Registrierung für unseren Newsletter freiwillig mitteilen.</p>
            <p>2.2 Wir verwenden die von Ihnen mitgeteilten Daten zur Vertragsabwicklung und Bearbeitung Ihrer Anfragen. Die Datenverarbeitung erfolgt zur Erfüllung des Vertrags und ist für die Durchführung vorvertraglicher Maßnahmen erforderlich.</p>

            <p><strong>Weitergabe personenbezogener Daten</strong></p>
            <p>3.1 Eine Weitergabe Ihrer Daten erfolgt nur, soweit dies zur Vertragsabwicklung erforderlich ist. Dies umfasst insbesondere die Weitergabe an Transportunternehmen zur Lieferung der Waren sowie an Zahlungsdienstleister zur Abwicklung von Zahlungen.</p>
            <p>3.2 Ihre Daten werden nicht an Dritte zu Werbezwecken weitergegeben.</p>

            <p><strong>Speicherdauer</strong></p>
            <p>4.1 Personenbezogene Daten, die im Rahmen der Bestellung oder Registrierung erhoben werden, werden nur solange gespeichert, wie dies für die Vertragsabwicklung erforderlich ist bzw. wie es die gesetzlichen Aufbewahrungsfristen vorsehen.</p>
            <p>4.2 Geschäftsdaten, Produkte und Bestellungen seitens der Verkäufer werden entsprechend den gesetzlichen Vorgaben gespeichert und für die Dauer von handels- und steuerrechtlichen Aufbewahrungsfristen vorgehalten.</p>

            <p><strong>Ihre Rechte</strong></p>
            <p>5.1 Sie haben das Recht, jederzeit Auskunft über die zu Ihrer Person gespeicherten Daten zu erhalten.</p>
            <p>5.2 Sie haben das Recht auf Berichtigung, Löschung oder Sperrung Ihrer Daten.</p>
            <p>5.3 Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, für Auskünfte, Berichtigung, Sperrung oder Löschung von Daten sowie für den Widerruf erteilter Einwilligungen wenden Sie sich bitte an die oben genannte verantwortliche Stelle.</p>

            <p><strong>Schlussbestimmungen</strong></p>
            <p>6.1 Wir treffen Vorkehrungen zum Schutz Ihrer personenbezogenen Daten vor unbefugtem Zugriff, Verlust, Missbrauch oder Vernichtung.</p>
            <p>6.2 Diese Datenschutzerklärung kann jederzeit von uns geändert werden. Es gilt die jeweils aktuelle Fassung, die auf unserer Website veröffentlicht ist.</p>

            <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand <i>13.05.2024</i>.</p>

        </div>
    );
}

export default DataConsent;
