import './VendorCommercePage.css'
import Product from '../../services/classes/Product';
import Vendor from '../../services/classes/Vendor';
import Address from '../../services/classes/Address';
import '../components/VendorMiniMap/VendorMiniMap'

import { Card, CardGroup } from 'react-bootstrap';
import { Map } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import VendorMiniMap from "../components/VendorMiniMap/VendorMiniMap";
import { addToCart, getCartItemQuantity } from '../../services/cookieCart';
import { toast } from 'react-toastify';
import {baseUri} from "../../services/httpRequestor";
import {render} from "react-dom";
import { GoStar, GoStarFill } from 'react-icons/go';

function VendorCommercePage() {
    //--------------------------------------------------------------------
    const { vendorName } = useParams();

    const [products, setProducts] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [location, setLocation] = useState([48.6841594,10.1501465]);
    const [locEnabled, setLocEnabled] = useState(false);
    const [vendorFavs, setVendorFavs] = useState([]);
    const [isFavorite, setIsFavorite] = useState(null);

    function success(pos) {
        const latitude = pos.coords.latitude;
        const longitude = pos.coords.longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        setLocation([latitude, longitude]);
        setLocEnabled(true);
    }

    function error() {
        console.log("Unable to retrieve your location");
        setLocEnabled(false);
    }

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error)
    } else {
        console.log("Geolocation not supported");
    }

    const getVendorWithProducts = async () => {
        const responseProducts = await fetch(baseUri + '/products/' + vendorName);
        const statusProducts = responseProducts.status
        console.log(statusProducts)
        if (!checkStatus(statusProducts)){
            toast.error('Fehler beim Laden der Produkte.')
        } else {
            const bodyProducts = await responseProducts.body.getReader().read();
            const dataProducts = JSON.parse(new TextDecoder().decode(bodyProducts.value));
            console.log(dataProducts);
            setProducts(dataProducts);
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",

            },
            body: JSON.stringify({"Latitude" : location[0], "Longitude" : location[1]}),
        }



        const responseVendor = await fetch(baseUri + '/vendor/' + vendorName, requestOptions);
        const statusVendor = responseVendor.status
        console.log(statusVendor);
        if (!checkStatus(statusVendor)){
            toast.error('Fehler beim Laden des Verkäufers.')
        } else {
            const bodyVendor = await responseVendor.body.getReader().read();
            const dataVendor = JSON.parse(new TextDecoder().decode(bodyVendor.value));
            console.log(dataVendor);
            setVendor(dataVendor);
        }

    }


    useEffect(() => {
        getVendorWithProducts();
    }, [])

    const checkStatus = (status) => {
        if (status === 200) {
            return true;
        } else {
            return false;
        }
    }

    // Definition der isFavVendor-Funktion außerhalb der getVendorFavs-Funktion
    function isFavVendor(vendorFavs, vendorName) {
        if (vendorFavs.length > 0) {
            const favVendorNames = vendorFavs.map(favVendor => favVendor.name);
            console.log("Vendor Name:", vendorName);
            console.log("Vendor Name Favorites:", favVendorNames);
            return favVendorNames.includes(vendorName);
        } else {
            return false;
        }
    }

    const getVendorFavs = async () => {
        const token = localStorage.getItem('token');

        // Überprüfen, ob ein Token vorhanden ist
        if (!token) {
            // Setze den Favoritenstatus auf nicht favorisiert und beende die Funktion
            setIsFavorite(false);
            return;
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization" : "Bearer " + token,
            },
        }

        const responseFavs = await fetch(baseUri + '/vendor/favourite/list', requestOptions);
        const statusFavs = responseFavs.status;
        console.log(statusFavs)
        if (!checkStatus(statusFavs)){
            toast.error('Fehler beim Laden des Favoritenstatus.')
        } else {
            const bodyFavs = await responseFavs.body.getReader().read();
            const dataFavs = JSON.parse(new TextDecoder().decode(bodyFavs.value));
            console.log(dataFavs);
            setVendorFavs(dataFavs);
            console.log("Calling isFavVendor...");
            const isVendorFav = isFavVendor(dataFavs, vendorName); // Übergabe von vendorFavs und vendorName an die Funktion
            if (isVendorFav){
                setIsFavorite(true);
            } else {
                setIsFavorite(false);
            }
        }
    }



    useEffect(() => {
        getVendorFavs();
    }, [])


    //--------------------------------------------------------------------
    //handle Funktionen
    const handleAddToCart = (product) => {
        if (product.stock <= getCartItemQuantity(product.id)){
            toast.error("Es befinden sich zu viele Produkt im Warenkorb!");
            return false;
        } else {
            addToCart(product);
            toast.info(product.name + " (" + getCartItemQuantity(product.id)+ ") " + "zu Warenkorb hinzugefügt!")
            return true;
        }
    };

    //--------------------------------------------------------------------
    //handle Favorite Funktionen
    const toggleFavorite = () => {
        if (isFavorite) {
            console.log('removing')
            removeFavorite(); // Wenn entfavorisiert wird, rufe removeFavorite auf
        } else {
            console.log('adding')
            addFavorite(); // Wenn favorisiert wird, rufe addFavorite auf
        }
    };

    const addFavorite = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            // Anzeigen einer Benachrichtigung, dass der Benutzer eingeloggt sein muss
            toast.error('Loggen Sie sich ein, um Favoriten hinzuzufügen.');
            return;
        }

        setIsFavorite(null);
        const requestOptionsAddFav = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization" : "Bearer " + token,
            },
        }

        const responseAddFav = await fetch(baseUri + '/vendor/favourite/add/' + vendorName, requestOptionsAddFav);
        const statusAddFav = responseAddFav.status;
        if (statusAddFav === 200){
            setIsFavorite(true);
            toast.success("Favorit hinzugefügt.");
        } else {
            toast.error("Fehler beim Hinzufügen des Favorits.")
        }

    };

    const removeFavorite = async () => {
        setIsFavorite(null);
        const token = localStorage.getItem('token');
        const requestOptionsRemoveFav = {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization" : "Bearer " + token,
            },
        }

        const responseRemoveFav = await fetch(baseUri + '/vendor/favourite/remove/' + vendorName, requestOptionsRemoveFav);
        const statusRemoveFav = responseRemoveFav.status;
        if (statusRemoveFav === 200){
            setIsFavorite(false);
            toast.success("Favorit entfernt.");
        } else {
            toast.error("Fehler beim Entfernen des Favorits.")
        }
    };


    //--------------------------------------------------------------------
    //

    return (
        <div style={{margin: '2em'}}>
            {vendor ? (
                <div className="Heading">
                    <h1 style={{margin: '2em', marginBottom: '1em'}}>{vendor.name}
                        <br/>
                        <span onClick={toggleFavorite}>
                            { (isFavorite !== null) ? ((isFavorite === true) ? (<GoStarFill color="orange"/>) : (<GoStar color="orange"/>)) : (<></>) }

                        </span>
                    </h1>
                </div>
            ) : (
                <div className="text-center">Loading...</div>
            )}

            {vendor && vendor.address ? (
                <div className="text-center">
                    <h5>Adresse: {vendor.address.road} {vendor.address.houseNumber}, {vendor.address.zipCode} {vendor.address.town}, {vendor.address.country}</h5>
                    {locEnabled ? (
                        <h5>{vendor.distance.toFixed(2)} km entfernt </h5>
                    ) : (
                        <small>(Aktiviere die Standortfreigabe um deine Entfernung anzuzeigen.)</small>
                    )}
                </div>
            ) : (
                <div className="text-center">Loading...</div>
            )}

            <div className="Heading text-center">
                <h2 style={{margin: '2em', marginBottom: '1em'}}>Produkte</h2>
            </div>

            {/* Produkte des Vendors */}
            <div className="container">
                <div className="row justify-content-center">

                    {products.map(product => (
                        <Card className={"customCardCSS productCard"} key={product.id}
                              style={{width: "18rem", margin: "1em"}}>
                            <Card.Body>
                                <Card.Title>{product.name}</Card.Title>
                                <Card.Text>{product.description}</Card.Text>
                                <Card.Text>Preis: {product.price} €</Card.Text>
                                <Card.Text>Status:
                                    {product.stock > 10 && (
                                        <span style={{color: 'green'}}> Lieferbar</span>
                                    )}
                                    {product.stock > 0 && product.stock <= 10 && (
                                        <span style={{color: 'orange'}}> Nur noch {product.stock} auf Lager</span>
                                    )}
                                    {product.stock <= 0 && (
                                        <span style={{color: '#d63b28'}}> Nicht vorrätig</span>
                                    )}
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer className="d-flex justify-content-center">
                                <a
                                    className={`btn btn-light btn-block ${product.stock <= 0 ? 'disabled' : ''}`}
                                    role="button"
                                    style={{pointerEvents: product.stock <= 0 ? 'none' : 'auto'}}
                                    onClick={() => handleAddToCart(product)}>
                                    Zum Warenkorb hinzufügen
                                </a>
                            </Card.Footer>
                        </Card>
                    ))}

                </div>
            </div>
        </div>
    );
}

export default VendorCommercePage;