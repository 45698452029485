import './personalInfo.css'
import {Row, Col, Form, Button} from 'react-bootstrap';
import { toast } from 'react-toastify';

import validatePassword from "../../services/validatePassword";
import {baseUri} from "../../services/httpRequestor";
import {useEffect, useState} from "react";
import ReactFlagsSelect from "react-flags-select";

function PersonalInfo() {
    let isVendor = localStorage.getItem("isVendor")
    console.log(isVendor);
    let token = window.localStorage.getItem("token");
    
    let [userInfo, setUserInfo] = useState(null);
    const [selected, setSelected] = useState("");

    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization" : "Bearer " + token,
        },
    }

    const uri = baseUri.concat("/user/get");
    const getInfo = async () => {
        const response = await fetch(uri, requestOptions);

        if (response.status === 200) {
            const stream = response.body.getReader();
            const reader = await stream.read();
            const text = new TextDecoder().decode(reader?.value);
            console.log(text);
            const value = JSON.parse(text);
            setUserInfo(value);
            if (value.address && value.address.address &&  value.address.address.country) {
                setSelected(value.address.address.country);
            } else {
                setSelected("");
            }
        }
        else {
            toast.error("Failed to fetch User Info")
        }
    };
    
    useEffect(() =>
    {
        getInfo();
    }, [])

    //------------------------Login required - Handling------------------------------
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
        localStorage.setItem("loginRequired", "Bitte loggen Sie sich ein.");
        window.location.href = "/login";
        return false;
    }
    //-------------------------------------------------------------------------------

    async function handleSubmit(event) {
        event.preventDefault();

        const firstName = event.target.elements.formGridFirstName.value;
        const lastName = event.target.elements.formGridLastName.value;
        const email = event.target.elements.formGridEmail.value;
        const address1 = event.target.elements.formGridAddress1.value;
        const address2 = event.target.elements.formGridAddress2.value;
        const city = event.target.elements.formGridCity.value;
        const state = selected;
        const zip = event.target.elements.formGridZip.value;

        const gewerblichCheckbox = event.target.elements.gewerblich;
        const privatCheckbox = event.target.elements.privat;

        if (!firstName || !lastName || !email || !address1 || !city || !state || !zip || !(gewerblichCheckbox.checked || privatCheckbox.checked)) {
            toast.error('Bitte füllen Sie alle erforderlichen Felder aus.');
            return false;
        }

        const userDTO = {
            firstName: firstName,
            name: lastName,
            email: email,
            password: "",
            address: {
                address: {
                    road: address1,
                    houseNumber: address2,
                    town: city,
                    country: state,
                    zipCode: zip
                },
            },
            buissness: gewerblichCheckbox.checked
        };

        const token = window.localStorage.getItem("token");

        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + token,
            },
            body: JSON.stringify(userDTO)
        }

        const uri = baseUri.concat("/user/update");

        const response = await fetch(uri, requestOptions);

        if (response.status === 200) {
            console.log(requestOptions);
            updatePersonalInfoSuccess(email)
        } else {
            toast.error("Fehler bei Datenaktualisierung!")
        }
    }

    function updatePersonalInfoSuccess(email) {
        localStorage.setItem('updatePersInfoSuccess', email);
        window.location.href = "/profile";
    }
    
    return (
        <>

            <div className="Heading">
                <h1 style={{margin: '2em'}}>Persönliche Daten</h1>
            </div>

            <div style={{marginRight: '20%', marginLeft: '20%'}}>
                <Form noValidate onSubmit={handleSubmit} id="formContainerPersonal">
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridFirstName">
                            <Form.Label>Vorname*</Form.Label>
                            <Form.Control defaultValue={userInfo == null ? "" : userInfo.firstName} placeholder="Vorname" required/>
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridLastName">
                            <Form.Label>Nachname*</Form.Label>
                            <Form.Control defaultValue={userInfo == null ? "" : userInfo.name} placeholder="Nachname" required/>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Email*</Form.Label>
                            <Form.Control type="email" defaultValue={userInfo == null ? "" : userInfo.email } placeholder="Email" required disabled/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Passwort</Form.Label>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                <span onClick={togglePopup}
                      style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue', marginRight: '5px'}}>
                    Passwort ändern
                </span>
                            </div>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridAddress1">
                            <Form.Label>Straße*</Form.Label>
                            <Form.Control defaultValue={userInfo?.address?.address?.road || ""} placeholder="Straße" required/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label>Hausnummer</Form.Label>
                            <Form.Control defaultValue={userInfo?.address?.address?.houseNumber || ""} placeholder="Hausnummer" required/>
                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridCity">
                            <Form.Label>Stadt*</Form.Label>
                            <Form.Control defaultValue={userInfo?.address?.address?.town || ""} placeholder="Stadt" required/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridZip">
                            <Form.Label>PLZ*</Form.Label>
                            <Form.Control defaultValue={userInfo?.address?.address?.zipCode || ""} placeholder="PLZ" required/>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridState">
                            <Form.Label>Staat*</Form.Label>
                            <ReactFlagsSelect selected={selected} onSelect={(code) => setSelected(code)}
                            />

                        </Form.Group>
                    </Row>

                    <Row className="mb-3">
                        <Col>
                            <Form.Label>Kontotyp*</Form.Label>
                            <div>
                                <Form.Check inline type="radio" label="Gewerblich" name="accountType" id="gewerblich"
                                            value="Gewerblich" defaultChecked={userInfo && userInfo.buissness === true} required/>
                                <Form.Check inline type="radio" label="Privat" name="accountType" id="privat"
                                            value="Privat" defaultChecked={userInfo && userInfo.buissness !== true} required/>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col className="text-end">
                            <Button class="btn-primary" type="submit">
                                Änderungen speichern
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <br/>
                <p><small>* Pflichtfeld</small></p>


                <div id="popupOverlay"
                     className="overlay-container">
                    <div className="popup-box">
                        <h2>Passwort ändern</h2>
                        <form className="form-container" onSubmit={passwordConfirm}>
                            <label className="form-label"
                                   htmlFor="name">
                                Altes Passwort*
                            </label>
                            <input className="form-input" type="password" id="old-password-input"
                                   placeholder="Altes Kennwort eingeben" required/>

                            <label className="form-label">Neues Passwort*</label>
                            <input className="form-input" id="new-password-input"
                                   type="password"
                                   placeholder="Neues Kennwort eingeben" required/>

                            <input className="form-input" id="repeat-password-input"
                                   type="password"
                                   placeholder="Neues Kennwort wiederholen" required/>

                            <button id='passwordConfirm' className="btn-submit"
                                    type="submit">
                                Übermitteln
                            </button>
                        </form>

                        <button className="btn-close-popup"
                                onClick={togglePopup} type="submit">
                            Abbrechen
                        </button>
                    </div>
                </div>
            </div>
        </>

    );
}

function togglePopup() {
    const overlay = document.getElementById('popupOverlay');
    overlay.classList.toggle('show');

}

async function passwordConfirm(event) {
    event.preventDefault();

    const oldPassword = document.getElementById('old-password-input').value;
    const newPassword = document.getElementById('new-password-input').value;
    const repeatPassword = document.getElementById('repeat-password-input').value;

    if (!!newPassword && !!repeatPassword) {
        if (newPassword === repeatPassword) {
            if (validatePassword(newPassword)) {
                //toast.success("Passwort aktualisiert.")
                const token = window.localStorage.getItem("token");
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer " + token,
                    },
                    body: JSON.stringify({
                        "oldPassword": oldPassword,
                        "newPassword" : newPassword
                    })
                }
                const response = await fetch(baseUri.concat("/user/updatepassword"), requestOptions);
                if (response.status === 200) {
                    toast.success("Passwort erfolgreich aktualisiert.");
                }
                else {
                    toast.error("Fehler bei Passwortaktualisierung.")
                }


                togglePopup();
                return true;
            } else {
                toast.error("Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Ziffer und ein Sonderzeichen enthalten.");
            }
        } else {
            // alert('Passwörter stimmen nicht überein!')
            toast.error('Passwörter stimmen nicht überein!');
            return false;
        }
    }
}





export default PersonalInfo;