import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import HeaderBar from './pages/components/HeaderBar/HeaderBar';
import Footer from './pages/components/Footer/Footer';
import LandingPage from './pages/landingPage/LandingPage';
import ProfilePage from './pages/profilePage/ProfilePage';
import LoginPage from './pages/loginPage/loginPage';
import PersonalInfo from './pages/personalInfo/personalInfo';
import SignUpPage from './pages/signUpPage/SignUpPage';
import MyOrders from './pages/MyOrders/MyOrders';
import MyAddresses from './pages/MyAddresses/MyAddresses';
import MyVendors from './pages/MyVendors/MyVendors';
import MyPaymentMethods from './pages/MyPaymentMethods/MyPaymentMethods';
import ProductListing from './pages/ProductListing/ProductListing';
import ImpressumPage from './pages/ImpressumPage/impressumPage';
import Logout from './pages/loginPage/logout';
import VendorCommercePage from './pages/VendorCommercePage/VendorCommercePage';
import VendorProducts from './pages/VendorProducts/VendorProducts';
import VendorSignup from './pages/RegisterVendor/RegisterVendor';
import Cart from './pages/Cart/Cart';
import Checkout from './pages/Checkout/Checkout';
import OrderSuccess from './pages/OrderSuccess/OrderSuccess';
import VendorProfile from './pages/VendorProfile/VendorProfile';
import VendorInfo from './pages/VendorInfo/VendorInfo';
import VendorTasks from './pages/VendorTasks/VendorTasks';
import AGB from './pages/AGB/AGB';
import DataConsent from './pages/DataConsent/DataConsent';

const root = ReactDOM.createRoot(document.getElementById('root'));
const baseUri = '';

root.render(
    <>
        <HeaderBar />
        
        <Router>
          <div id="contendViewOnIndex">
            <ToastContainer style={{paddingTop: '100px'}}/>
            <Routes>
                <Route path='' element={<LandingPage />} />
                <Route path='/profile' element={<ProfilePage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/logout' element={<Logout />} />
                <Route path='/personalInfo' element={<PersonalInfo />} />
                <Route path='/signup' element={<SignUpPage />} />
                <Route path='/myOrders' element={<MyOrders />} />
                <Route path='/myAddresses' element={<MyAddresses />} />
                <Route path='/myVendors' element={<MyVendors />} />
                <Route path='/myPaymentMethods' element={<MyPaymentMethods />} />
                <Route path='/marketplace' element={<ProductListing />} />
                <Route path='/impressum' element={<ImpressumPage />} />
                <Route path='/marketplace/:vendorName' element={<VendorCommercePage />} />
                <Route path='/VendorProducts' element={<VendorProducts />} />
                <Route path='/registerVendor' element={<VendorSignup />} />
                <Route path='/cart' element={<Cart />} />
                <Route path='/checkout' element={<Checkout />} />
                <Route path='/orderSuccess' element={<OrderSuccess />} />
                <Route path='/VendorProfile' element={<VendorProfile />} />
                <Route path='/VendorInfo' element={<VendorInfo />} />
                <Route path='/VendorTasks' element={<VendorTasks />} />
                <Route path='/AGB' element={<AGB />} />
                <Route path='/DataConsent' element={<DataConsent />} />
            </Routes>
            </div>
        </Router>


        <Footer />
    </>
);

reportWebVitals();
