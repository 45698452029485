import './ProfilePage.css'
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {baseUri} from "../../services/httpRequestor";

function ProfilePage() {
    //------------------------Toast Fehlermeldungen Handling GET------------------------------
    let updatePersonalInfoSuccess = localStorage.getItem('updatePersInfoSuccess');
    if (updatePersonalInfoSuccess) {
        toast.success("Persönliche Daten erfolgreich aktualisiert!");
        localStorage.removeItem('updatePersInfoSuccess');
    }

    let alreadyVendorMessage = localStorage.getItem('alreadyVendor')
    if (alreadyVendorMessage) {
        toast.error(alreadyVendorMessage);
        localStorage.removeItem('alreadyVendor');
    }

    let noVendor = localStorage.getItem('noVendor');
    if (noVendor) {
        toast.error(noVendor);
        localStorage.removeItem('noVendor');
    }

    let vendorRegSuccess = localStorage.getItem('vendorRegSuccess');
    if (vendorRegSuccess) {
        toast.success(vendorRegSuccess);
        localStorage.removeItem('vendorRegSuccess');
    }

    //------------------------User Handling------------------------------
    let token = window.localStorage.getItem("token");
    let [userInfo, setUserInfo] = useState(null);

    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization" : "Bearer " + token,
        },
    }
    const uri = baseUri.concat("/user/get");

    const getInfo = async () => {
        const response = await fetch(uri, requestOptions);

        if (response.status === 200) {
            const stream = response.body.getReader();
            const reader = await stream.read();
            const text = new TextDecoder().decode(reader?.value);
            console.log(text);
            const value = JSON.parse(text);
            setUserInfo(value);
        }
        else {
            toast.error("Failed to fetch User Info")
        }
    };

    useEffect(() =>
    {
        getInfo();
    }, [])

    //------------------------Toast Fehlermeldungen Handling SET------------------------------
    if (userInfo && (userInfo.vendor != null)){
        localStorage.setItem("isVendor", "true");
    } else {
        localStorage.setItem("isVendor", "false");
    }

    //------------------------Login required - Handling------------------------------
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
        localStorage.setItem("loginRequired", "Bitte loggen Sie sich ein.");
        window.location.href = "/login";
        return false;
    }
    //-------------------------------------------------------------------------------

    console.log(localStorage.getItem("isVendor"))

    return (
        <div style={{margin: '2em'}}>
            <div className="Heading">
                <h1 style={{margin: '2em'}}>Mein Account</h1>
            </div>

            <div className="row row-cols-1 row-cols-md-5 g-4">
                <div className="col">
                    <div className="card customCardCSS h-100">
                        <img src={require("../../static/ProfilePage/persönliche-daten.png")} className="card-img-top"
                             alt="Persönliche Daten"/>
                        <div className="card-body">
                            <h5 className="card-title">Persönliche Daten</h5>
                            <p className="card-text">
                                Name, E-Mail, Anschrift und Passwörter
                            </p>
                        </div>
                        <div className="card-footer">
                            <a href="/personalInfo" className="btn btn-light" role="button">Anzeigen</a>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card customCardCSS h-100">
                        <img src={require("../../static/ProfilePage/meine-bestellungen.png")} className="card-img-top"
                             alt="Meine Bestellungen"/>
                        <div className="card-body">
                            <h5 className="card-title">Meine Bestellungen</h5>
                            <p className="card-text">Bestellungsübersicht, Bestellungen verfolgen, stornieren und
                                Rechnungen
                                herunterladen</p>
                        </div>
                        <div className="card-footer">
                            <a href="/myOrders" className="btn btn-light" role="button">Anzeigen</a>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card customCardCSS h-100">
                        <img src={require("../../static/ProfilePage/meine-adressen.png")} className="card-img-top"
                             alt="Meine Adressen"/>
                        <div className="card-body">
                            <h5 className="card-title">Meine Adressen</h5>
                            <p className="card-text">Paketstationen, Liefer- und Rechnungsadressen</p>
                        </div>
                        <div className="card-footer">
                            <a href="/myAddresses" className="btn btn-light" role="button">Anzeigen</a>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card customCardCSS h-100">
                        <img src={require("../../static/ProfilePage/gewählte-zahlungsarten.png")}
                             className="card-img-top"
                             alt="Gewählte Zahlungsarten"/>
                        <div className="card-body">
                            <h5 className="card-title">Gewählte Zahlungsarten</h5>
                            <p className="card-text">
                                Zahlungsarten, Bankverknüpfungen und Drittanbieter
                            </p>
                        </div>
                        <div className="card-footer">
                            <a href="/myPaymentMethods" className="btn btn-light" role="button">Anzeigen</a>
                        </div>
                    </div>
                </div>
                <div className="col">
                    <div className="card customCardCSS h-100">
                        <img src={require("../../static/ProfilePage/meine-händler.png")} className="card-img-top"
                             alt="Meine Händler"/>
                        <div className="card-body">
                            <h5 className="card-title">Meine Händler</h5>
                            <p className="card-text">
                                Gemerkte Händler und Anbieter
                            </p>
                        </div>
                        <div className="card-footer">
                            <a href="/myVendors" className="btn btn-light" role="button">Anzeigen</a>
                        </div>
                    </div>
                </div>
            </div>

            <br/>
            <h5>Geschäftsoptionen:</h5>
            {userInfo && (userInfo.vendor != null) ? (
                <p><a href='/vendorProfile'>Geschäft verwalten</a></p>
            ) : (
                <p><a href='/registerVendor'>Geschäft registrieren</a></p>
            )}

            <br/>
            <p><small> &copy; Icons made by Freepik, Uniconlabs, Flat Icons, Pixel perfect, Assia Benkerroum from
                www.flaticon.com</small></p>

        </div>

    );
}

export default ProfilePage;