import './MyAddresses.css'
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import {baseUri} from "../../services/httpRequestor";
import isValidInteger from "../../services/isValidInteger";

function MyAddresses(){
    // localStorage Messaging Handle
    const AddAddressSuccess = localStorage.getItem("AddAddressSuccess");
    if (AddAddressSuccess){
        toast.success(AddAddressSuccess);
        localStorage.removeItem("AddAddressSuccess");
    }

    const ADeleteSuccess = localStorage.getItem("ADeleteSuccess");
    if (ADeleteSuccess){
        toast.success(ADeleteSuccess);
        localStorage.removeItem("ADeleteSuccess");
    }


    let [userInfo, setUserInfo] = useState(null);
    let [selectedFlag, setSelectedFlag] = useState(null);
    let [loading, setLoading] = useState(true);
    const token = localStorage.getItem('token');

    const requestOptions = {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization" : "Bearer " + token,
        },
    }

    const uri = baseUri.concat("/user/get");
    const getInfo = async () => {
        const response = await fetch(uri, requestOptions);

        if (response.status === 200) {
            const stream = response.body.getReader();
            const reader = await stream.read();
            const text = new TextDecoder().decode(reader?.value);
            console.log(text);
            const value = JSON.parse(text);
            setUserInfo(value);
        }
        else {
            toast.error("Failed to fetch User Info")
        }
    };

    useEffect(() =>
    {
        getInfo();
    }, [])

    const [address, setAddress] = useState([]);
    
    async function getAddresses() {
        const token = localStorage.getItem('token');
        const response = await fetch(baseUri + '/user/addresses', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        });
        
        if (response.status === 200) {
            const stream = await response.body.getReader().read();
            const text = new TextDecoder().decode(stream.value);
            const data = JSON.parse(text);
            setAddress(data);
            setSelectedFlag(data.country);
            setLoading(false);
        }
    }
    
    useEffect(() => {
        getAddresses();
    }, []);
    console.log("after use effect : " + JSON.stringify(address));

    //------------------------Login required - Handling------------------------------
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
        localStorage.setItem("loginRequired", "Bitte loggen Sie sich ein.");
        window.location.href = "/login";
        return false;
    }
    //-------------------------------------------------------------------------------


    function RenderAddressCard(id, type, name, streetName, streetNumber, zip, city, country, paketstationNum){
        return (
            <div id="adressCard" className="card customCardCSS mb-2">
                <div className="row g-0">
                    <div className="col-md-6">
                        <div className="card-body">
                            { type === "Paketstation" ? (
                                <h5 className="card-title">{name} {paketstationNum}</h5>) : (
                                <h5 className="card-title">{name}</h5>
                            )}
                            <p className="card-text"><a href="#" className="text-decoration-none">{type}</a></p>
                            <span
                                onClick={() => deleteAddress(id)}
                                style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    color: '#d63b28',
                                    verticalAlign: 'bottom'
                                }}>Löschen</span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card-body">
                            <p className="card-text">{streetName} {streetNumber}</p>
                            <p className="card-text">{zip}, {city}</p>
                            <p className="card-text">{country}</p>
                            <span
                                onClick={() => togglePopup(id, type, name, streetName, streetNumber, zip, city, country, paketstationNum)}
                                style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    color: 'blue',
                                    marginRight: '5px'
                                }}>Bearbeiten</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    function togglePopup(id, type, name, streetName, streetNumber, zip, city, country, paketstationNum) {
        const overlay = document.getElementById('popupOverlay');
        const nameInput = document.getElementById('addressName');
        const streetNameInput = document.getElementById('streetName');
        const streetNumberInput = document.getElementById('streetNumber');
        const zipInput = document.getElementById('zipCode');
        const cityInput = document.getElementById('city');
        const countryInput = document.getElementById('StateCountry');
        const typeDropdown = document.getElementById('addressType');
        const packStationNumberInput = document.getElementById('packStationNumber');

        nameInput.value = name;
        streetNameInput.value = streetName;
        streetNumberInput.value = streetNumber;
        zipInput.value = zip;
        cityInput.value = city;
        countryInput.value = country;


        // Set the selected option in the dropdown
        typeDropdown.value = type;

        // Show or hide the pack station number input based on the selected type
        if (type === 'Paketstation') {
            packStationNumberInput.style.display = 'block';
            packStationNumberInput.style.textAlign = 'left';
            const packStationNumberVal = document.getElementById('packStationNumberVal');
            packStationNumberVal.value = paketstationNum;
        } else {
            let packStationNumberVal = document.getElementById('packStationNumberVal');
            packStationNumberVal.value = ''; // set emtpy string to clear value from former Popups
            packStationNumberInput.style.display = 'none';
        }

        overlay.classList.toggle('show');
    }

    function toggleTypeInput(){
        const typeDropdown = document.getElementById('addressType').value;
        const packStationNumberInput = document.getElementById('packStationNumber');

        if (typeDropdown === 'Paketstation') {
            packStationNumberInput.style.display = 'block';
            packStationNumberInput.style.textAlign = 'left';
        } else {
            packStationNumberInput.style.display = 'none';
        }
    }

    async function addressConfirm(event){
        event.preventDefault();

        const typeDropdown = document.getElementById('addressType');

        const addressIdInput = document.getElementById('addressId');
        const nameInput = document.getElementById('addressName');
        const streetNameInput = document.getElementById('streetName');
        const streetNumberInput = document.getElementById('streetNumber');
        const zipInput = document.getElementById('zipCode');
        const cityInput = document.getElementById('city');
        const countryInput = document.getElementById('StateCountry');
        let packstationVal = document.getElementById('packStationNumberVal');

        if (nameInput.value === 'Home'){
            toast.error("Die Addressbezeichnung darf nicht \"Home\" sein.");
            return false;
        }

        if (!nameInput.value || !streetNameInput.value || !streetNumberInput.value || !zipInput.value || !cityInput.value || !countryInput.value) {
            toast.error("Daten unvollständig.");
            return false;
        } else {
            if (!isValidInteger(zipInput.value)) {
                toast.error("Postleitzahl muss eine Ganzzahl sein.");
                return false;
            }
            if (!isValidInteger(streetNumberInput.value)) {
                toast.error("Hausnummer muss eine Ganzzahl sein.");
                return false;
            }

            if (typeDropdown.value === 'Paketstation') {
                if (!packstationVal.value) {
                    toast.error("Daten unvollständig.");
                    return false;
                } else {

                    if (!isValidInteger(packstationVal.value)){
                        toast.error("Paketstationsnummer muss eine Ganzzahl sein.");
                        return false;
                    }

                    console.log(packstationVal)
                    const token = localStorage.getItem('token');
                    const requestOptionsUpdateAddressPOBox = {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Authorization": "Bearer " + token,
                        },
                        body: JSON.stringify({
                            "lable" : nameInput.value,
                            "poBox" : true,
                            "poNumber" : parseInt(packstationVal.value),
                            "town": cityInput.value,
                            "zipCode": parseInt(zipInput.value),
                            "road": streetNameInput.value,
                            "houseNumber" : streetNumberInput.value,
                            "country": countryInput.value,
                        })
                    }
                    console.log(requestOptionsUpdateAddressPOBox);
                    const responseUpdateAddress = await fetch(baseUri.concat("/user/updateAddress"), requestOptionsUpdateAddressPOBox);
                    console.log(responseUpdateAddress);
                    if (responseUpdateAddress.status === 200) {
                        localStorage.setItem("AddAddressSuccess", "Paketstationsadresse \"" + nameInput.value + "\" erfolgreich übermittelt.");
                        window.location.reload();
                        return true;

                    }
                    else {
                        toast.error("Fehler bei Datenübermittlung aufgetreten.")
                        return false;
                    }
                }
            } else if (typeDropdown.value === 'Lieferadresse') {
                packstationVal = ''; // always safe empty string if type is 'Lieferadresse'
                const token = localStorage.getItem('token');
                const requestOptionsUpdateAddress = {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": "Bearer " + token,
                    },
                    body: JSON.stringify({
                        "lable" : nameInput.value,
                        "poBox" : false,
                        "poNumber" : parseInt(packstationVal),
                        "town": cityInput.value,
                        "zipCode": parseInt(zipInput.value),
                        "road": streetNameInput.value,
                        "houseNumber" : streetNumberInput.value,
                        "country": countryInput.value,
                        })
                }
                console.log(requestOptionsUpdateAddress);
                const responseUpdateAddress = await fetch(baseUri.concat("/user/updateAddress"), requestOptionsUpdateAddress);
                console.log(responseUpdateAddress);
                if (responseUpdateAddress.status === 200) {
                    localStorage.setItem("AddAddressSuccess", "Lieferadresse \"" + nameInput.value + "\" erfolgreich übermittelt.");
                    window.location.reload();
                    return true;
                }
                else {
                    toast.error("Fehler bei Datenübermittlung aufgetreten.")
                }
                return true;
            } else {
                toast.error("Fehler bei Aktualisierung der Lieferadresse.")
                return false;
            }
        }
    }

    //------------------------------------------------------------------------
    const deleteAddress = async (addressId) => {
        const requestOptionsADelete = {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + token,
            },
        }
        console.log(requestOptionsADelete);
        const responseADelete = await fetch(baseUri.concat("/user/removeAddress/" + addressId), requestOptionsADelete);
        console.log(responseADelete);

        if (responseADelete.status === 200){
            localStorage.setItem("ADeleteSuccess", "Addresse erfolgreich gelöscht.");
            window.location.reload();
        } else if (responseADelete.status === 404) {
            toast.error("Fehler: Produkt nicht gefunden.")
        } else {
            toast.error("Fehler bei Datenübertragung");
        }

        console.log("Produkt mit der ID", addressId, "wird gelöscht.");
    };
    //------------------------------------------------------------------------
    var addressCards = address.map(a => {
        console.log(a);
       return  RenderAddressCard(a.id, `${((a.poBox === true) ? "Paketstation" : "Lieferadresse")}`, a.lable, a.address.road, a.address.houseNumber, a.address.zipCode, a.address.town, a.address.country, a.poNumber);
    })
    console.log(address)

    //id, type, name, streetNameNumber, zip, city, country, paketstationNum
    
    //for (const a in address) {
    //    console.log("Address : " + a + " - " + typeof(a));
    //    if (a == 0) {continue;}
    //    addressCards.push(RenderAddressCard(a.id, "", (a.address.road + " " + a.address.houseNumber ), a.address.zipCode, a.address.town, a.address.country, a.poNumber));
    //}

    return (
        <div style={{margin: '2em'}}>
            <div className="Heading">
                <h1 style={{margin: '2em'}}>Meine Adressen</h1>
            </div>
            <div className="container">
                <center>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        address.length === 0 ? (
                            <p>Keine Adressen gespeichert.</p>
                        ) : (
                            addressCards
                        )
                    )}
                <a onClick={() => togglePopup("", "Lieferadresse", "", "", "", "", "", "")}>
                    <div id="addAdressCard" className="text-center card customCardCSS mb-2">
                        <div className="row g-0">
                            <div className="col-md-12">
                                <div className="card-body">
                                    <h5 className="card-title">Adresse hinzufügen</h5>
                                    <p className="card-text"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
                </center>

            </div>

            <div id="popupOverlay" className="overlay-container">
                <div className="popup-box" id="popup-box-address">
                    <h2>Adresse anlegen/ändern</h2>
                    <form className="form-container" onSubmit={addressConfirm}>
                        <input id="addressId" type="hidden" required/>
                        <label className="form-label" htmlFor="addressType">
                            Adresse Typ
                        </label>
                        <select className="form-input" id="addressType" onChange={toggleTypeInput} required>
                            <option value="Lieferadresse">Lieferadresse</option>
                            <option value="Paketstation">Paketstation</option>
                        </select>

                        <label className="form-label" htmlFor="name">
                            Adressbezeichnung
                        </label>
                        <input className="form-input" type="text" id="addressName" placeholder="Bezeichnung" required/>


                        <label className="form-label">Adressdaten</label>
                        <input className="form-input" id="streetName" type="text" placeholder="Straßenname" required/>
                        <input className="form-input" id="streetNumber" type="text" placeholder="Hausnummer" required/>
                        <input className="form-input" id="zipCode" type="text" placeholder="Postleitzahl" required/>
                        <input className="form-input" id="city" type="text" placeholder="Stadt" required/>
                        <input className="form-input" id="StateCountry" type="text" placeholder="Land"
                               required/>

                        <div id="packStationNumber" style={{display: 'none'}}>
                            <label className="form-label" htmlFor="packStationNumber">
                                Paketstationsnummer
                            </label>
                            <input className="form-input" id="packStationNumberVal" type="text"
                                   placeholder="Packstation Nr."/>
                        </div>

                        <button id='addressConfirm' className="btn-submit-address" type="submit">
                            Übermitteln
                        </button>
                    </form>

                    <button className="btn-close-popup-address" onClick={togglePopup}>
                    Abbrechen
                    </button>
                </div>
            </div>


        </div>
    );

}

export default MyAddresses;