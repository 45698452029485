import "./MyVendors.css";
import { toast } from "react-toastify";
import {baseUri} from "../../services/httpRequestor";
import {useEffect, useState} from "react";

function MyVendors() {
    const [vendorFavs, setVendorFavs] = useState([]);
    const [loading, setLoading] = useState(true);

    const deletedVendor = localStorage.getItem('deletedVendor');
    if (deletedVendor) {
        toast.success(deletedVendor);
        localStorage.removeItem('deletedVendor');
    }

    const getVendorFavs = async () => {
        const token = localStorage.getItem('token');
        const requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization" : "Bearer " + token,
            },
        }

        const responseFavs = await fetch(baseUri + '/vendor/favourite/list', requestOptions);
        const statusFavs = responseFavs.status;
        console.log(statusFavs)
        if (!checkStatus(statusFavs)){
            toast.error('Fehler beim Laden des Favoritenstatus.')
        } else {
            const bodyFavs = await responseFavs.body.getReader().read();
            const dataFavs = JSON.parse(new TextDecoder().decode(bodyFavs.value));
            console.log(dataFavs);
            setVendorFavs(dataFavs);
            setLoading(false);
        }

    }

    useEffect(() => {
        getVendorFavs();
    }, [])

    const removeFavorite = async (vendorName) => {
        const token = localStorage.getItem('token');
        const requestOptionsRemoveFav = {
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization" : "Bearer " + token,
            },
        }

        const responseRemoveFav = await fetch(baseUri + '/vendor/favourite/remove/' + vendorName, requestOptionsRemoveFav);
        const statusRemoveFav = responseRemoveFav.status;
        if (statusRemoveFav === 200){
            localStorage.setItem('deletedVendor', "Favorit \"" + vendorName + "\" entfernt.");
            window.location.reload();
            toast.success("Favorit entfernt.");
        } else {
            toast.error("Fehler beim Entfernen des Favorits.")
        }
    };

    const checkStatus = (status) => {
        if (status === 200) {
            return true;
        } else {
            return false;
        }
    }

    //------------------------Login required - Handling------------------------------
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
        localStorage.setItem("loginRequired", "Bitte loggen Sie sich ein.");
        window.location.href = "/login";
        return false;
    }
    //-------------------------------------------------------------------------------

    function renderVendorCard(name, road, houseNumber, town, zipCode, country) {
        return (
            <div className="card customCardCSS" style={{ width: "18rem", margin: "1em" }}>
                <div className="card-body">
                    <h5 className="card-title">{name}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{road + houseNumber}<br/>{zipCode + town}, {country}</h6>
                    <a href={"/marketplace/" + name} className="card-link">Händlerseite</a>
                    <a className="card-link" style={{cursor: 'pointer', textDecoration: 'underline', color: '#d63b28', verticalAlign: 'bottom'}}
                       onClick={() => removeFavorite(name)}>Entfernen
                    </a>
                </div>
            </div>
        );
    };

    let vendorCards = [];
    if (vendorFavs.length > 0) {
        vendorFavs.forEach(vendor => {
            vendorCards.push(renderVendorCard(vendor.name, vendor.address.road, vendor.address.houseNumber, vendor.address.town, vendor.address.zipCode, vendor.address.country));
        });
    } else {
        vendorCards = <p style={{textAlign: "center"}}>Keine Favoriten gespeichert.</p>;
    }


    return (
        <div style={{ margin: '2em' }}>
            <div className="Heading">
                <h1 style={{ margin: '2em' }}>Meine Verkäufer</h1>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    {loading ? <p style={{textAlign: "center"}}>Loading...</p> : vendorCards}
                </div>
            </div>
        </div>
    );
}

export default MyVendors;