function containsEmptyString(obj) {
    for (var key in obj) {
        if (typeof obj[key] === 'string' && obj[key].trim() === '') {
            return true;
        }
        if (typeof obj[key] === 'object' && containsEmptyString(obj[key])) {
            return true;
        }
    }
    return false;
}

export default containsEmptyString;