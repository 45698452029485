import "./MyPaymentMethods.css";
import { toast } from "react-toastify";

import sendApiRequest from '../../services/httpRequestor.js'
import PayMethod from "../../services/classes/payMethod";

function MyPaymentMethods() {

    //------------------------Login required - Handling------------------------------
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
        localStorage.setItem("loginRequired", "Bitte loggen Sie sich ein.");
        window.location.href = "/login";
        return false;
    }
    //-------------------------------------------------------------------------------

    var payMethods = [new PayMethod(1, "Kreditkarte", "Max Mustermann", "019248104210148", "481"),
    new PayMethod(2, "PayPal", "Max Mustermann", "/", "max.mustermann@icloud.com")];

    function renderPaymentCard(payMethodObj) {
        return (
            <div className="card customCardCSS" style={{ width: "18rem", margin: "1em" }} key={payMethodObj.id}>
                <div className="card-body">
                    <h5 className="card-title">{payMethodObj.type}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{payMethodObj.name}</h6>
                    <p className="card-text">{payMethodObj.getHiddenInf1()}</p>
                    <p className="card-text">Detail: {payMethodObj.getHiddenInf2()}</p>
                    <a href="#" className="card-link" onClick={() => delPayment(payMethodObj.id)}>Löschen</a>
                </div>
            </div>
        );
    };

    const paymentCards = payMethods.map(payment => renderPaymentCard(payment));

    function delPayment(id) {
        localStorage.setItem('deletedPaymentId', id);
        window.location.reload();
        return;
    };

    let deletedPaymentId = localStorage.getItem('deletedPaymentId');
    if (deletedPaymentId) {
        toast.success("Payment method with ID " + deletedPaymentId + " has been deleted!");
        localStorage.removeItem('deletedPaymentId');
    }

    const confirmPayment = () => {

        const payType = document.getElementById("selectedPaymentType").value;
        const payName = document.getElementById("payName").value;
        const payInf1 = document.getElementById("payInf1").value;
        const payInf2 = document.getElementById("payInf2").value;

        let newPayMethod = new PayMethod(Math.random(), payType, payName, payInf1, payInf2);
        // Debugging purposes
        alert(newPayMethod.id + " " + newPayMethod.type + " " + newPayMethod.name + " " + newPayMethod.payInf1 + " " + newPayMethod.payInf2 + " " + newPayMethod.getHiddenInf1() + " " + newPayMethod.getHiddenInf2());
        //addPayment(newPayMethod);

    };

    function togglePayPopup() {
        const overlay = document.getElementById('popupOverlay');

        overlay.classList.toggle('show');
    }

    return (
        <div>
            <div className="Heading">
                <h1 style={{margin: '2em'}}>Gewählte Zahlungsarten</h1>
            </div>
            <div className="container d-flex justify-content-center align-items-center">
                <div className="row justify-content-center">
                    {paymentCards}
                    <div className="text-center card customCardCSS mb-2" style={{width: "18rem", margin: "1em"}}
                         onClick={togglePayPopup}>
                        <div className="card-body">
                            <h5 className="card-title">Zahlungsart hinzufügen</h5>
                            <h1 className="card-text">+</h1>
                        </div>
                    </div>
                </div>
            </div>

            <div id="popupOverlay" className="overlay-container">
                <div className="popup-box" id="popup-box-address">
                    <h2>Zahlungsart hinzufügen</h2>
                    <form className="form-container" onSubmit={confirmPayment}>
                        <label className="form-label" htmlFor="paymentType">
                            Bezahlmethode
                        </label>
                        <select className="form-input" id="selectedPaymentType" required>
                            <option value="Kreditkarte">Kreditkarte</option>
                            <option value="Paypal">Paypal</option>
                        </select>

                        <label className="form-label" htmlFor="payOwnerName">
                            Name
                        </label>
                        <input className="form-input" type="text" id="payName" placeholder="Name" required/>


                        <label className="form-label">Zahlungsinformationen</label>
                        <input className="form-input" id="payInf1" type="text" placeholder="Information 1" required/>
                        <input className="form-input" id="payInf2" type="text" placeholder="Information 2" required/>

                        <button id='payConfirm' className="btn-submit-address" type="submit">
                            Übermitteln
                        </button>
                    </form>

                    <button className="btn-close-popup-address" onClick={togglePayPopup}>
                        Abbrechen
                    </button>
                </div>
            </div>

        </div>
    );
}

//HttpRequestor Funktion
async function addPayment(newPayMethod) {
    let response = await sendApiRequest('/', newPayMethod);

    alert(response.status);
}

export default MyPaymentMethods;
