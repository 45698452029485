import React from 'react';
import { Container } from 'react-bootstrap';
import { BsCheckCircle, BsXCircle } from 'react-icons/bs';
import {clearCart} from "../../services/cookieCart";

function OrderSuccess() {
    return (
        <Container className="text-center mt-5">
            <BsCheckCircle color="green" size={150} />
            <h1 className="mt-4">Bestellung erfolgreich abgeschlossen!</h1>
            <p className="mt-3">Vielen Dank für Ihre Bestellung.</p>
            <p className="mt-3">Ihre Bestellungsübersicht finden Sie <a href='/myOrders'>hier</a>!</p>
        </Container>
    );
}

function OrderFail(){
    return (
        <Container className="text-center mt-5">
            <BsXCircle color="#d63b28" size={150}/>
            <h1 className="mt-4">Bestellung fehlgeschlagen.</h1>
            <p className="mt-3">Ungültige Daten im Warenkorb.</p>
            <p className="mt-3">Zum Warenkorb <a href='/cart'>hier</a>!</p>
        </Container>
    );
}

function OrderSuccessPage() {
    const order = localStorage.getItem('orderStatus');

    if (order === "orderSuccess") {
        localStorage.removeItem('orderStatus');
        clearCart();
        return <OrderSuccess />
    } else if (order === 'orderFail') {
        localStorage.removeItem('orderStatus')
        return <OrderFail />;
    } else {
        return <><br/><h1 style={{color: "red"}}>You're not allowed to access this page.</h1></>
    }
}

export default OrderSuccessPage;
