import React, { useEffect, useState } from 'react';
import { Button, Nav, Navbar, Container, NavbarToggle, NavbarCollapse } from 'react-bootstrap';
import Logo from '../Logo/Logo';
import { getCart } from '../../../services/cookieCart';

function HeaderBar() {
    const [cartCount, setCartCount] = useState(0);

    useEffect(() => {
        setCartCount(getCart().length);

        const updateCartCount = () => {
            setCartCount(getCart().length);
        };
        window.addEventListener('cartUpdated', updateCartCount);

        return () => {
            window.removeEventListener('cartUpdated', updateCartCount);
        };
    }, []);

    let isLoggedIn = window.localStorage.getItem("isLoggedIn");
    if (isLoggedIn == null) {
        isLoggedIn = "false";
    }

    return (
        <Navbar expand="lg" bg="light" variant="light" className={`border-bottom border-2 ${isLoggedIn === "true" ? 'border-success' : 'border-danger'} d-flex align-items-center`}>
            <Container id="navbar-container">
                <Navbar.Brand href="/" className="d-flex align-items-center">
                    <Logo />
                </Navbar.Brand>
                <NavbarToggle aria-controls="navbarSupportedContent" />
                <NavbarCollapse id="navbarSupportedContent">
                    <Nav className="ms-auto">
                        <Nav.Link href="/marketplace">Marktplatz</Nav.Link>
                        {isLoggedIn === "true" && (
                            <>
                                <Nav.Link href="/profile">Mein Account</Nav.Link>
                            </>
                        )}
                        {cartCount > 0 && (
                            <Button variant="outline-dark" className="ms-3" href="/cart">Warenkorb ({cartCount})</Button>
                        )}
                        {isLoggedIn === "true" && (
                            <>
                                <Button variant="dark" className="ms-3" href="/logout">Ausloggen</Button>
                            </>
                        )}
                        {isLoggedIn !== "true" && (
                            <Button variant="dark" className="ms-3" href="/login">Einloggen/Registrieren</Button>
                        )}
                    </Nav>
                </NavbarCollapse>
            </Container>
        </Navbar>
    );
}

export default HeaderBar;