import './loginPage.css'

import sendApiRequest from '../../services/httpRequestor.js'
import LoginInfoDto from '../../services/dto/LoginInfoDto.js'

import { Button, Form, Row, Col } from 'react-bootstrap'

import React from 'react';
import {toast} from "react-toastify";

function LoginPage() {
    //------------------------Toast Fehlermeldungen Handling GET-----------------------------
    let LoginForVendorRegMessage = localStorage.getItem("LoginForVendorReg");
    if (LoginForVendorRegMessage) {
        toast.error(LoginForVendorRegMessage);
        localStorage.removeItem("LoginForVendorReg");
    }

    let loginRequired = localStorage.getItem("loginRequired");
    if (loginRequired){
        toast.error(loginRequired);
        localStorage.removeItem("loginRequired");
    }

    return (
        <div>
            <div className="Heading">
                <h1 style={{margin: '2em'}}>Login</h1>
            </div>

            <div className="login-container">
                <Form onSubmit={handleSubmit} id="loginForm">
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control id="logEmail" type="email" placeholder="Email eingeben" required/>
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Passwort</Form.Label>
                            <Form.Control id="logPassword" type="password" placeholder="Passwort eingeben" required/>
                        </Form.Group>
                    </Row>
                    <p className="text-center"><small>Noch keinen Account? Registriere Dich <a href="/signUp">hier</a>!</small>
                    </p>
                    <Row className="mb-3">
                        <Col className="text-center">
                            <Button class="btn-primary" type="submit">
                                Einloggen
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>

        </div>

    );

    function handleSubmit(event) {
        event.preventDefault();
        
        const email = document.getElementById("logEmail");
        const password = document.getElementById("logPassword");

        let logUserDto = new LoginInfoDto(email.value, password.value);
        login(logUserDto);
    }

}

//Login Funktion
export async function login(logUserDto) {

    let response = await sendApiRequest("/user/login", logUserDto);
    let responseStatus = response.status;
    
    if(responseStatus === 200) {
        const stream = response.body?.getReader();
        const reader  = await stream?.read();
        const value = new TextDecoder().decode(reader?.value);

        var token = JSON.parse(value).token;
        window.localStorage.setItem("token", token);
        window.localStorage.setItem("isLoggedIn", "true");
        //alert(token);
        toast.success("Login erfolgreich!");
        window.location.href = "/profile";
    }
    else {
        window.localStorage.setItem("token", "");
        window.localStorage.setItem("isLoggedIn", "false");
        window.localStorage.setItem("isVendor", "false")
        toast.error("Login fehlgeschlagen!");
    }
}

export default LoginPage;