//--------------------------------------------------------------------
//Shopping Cart Funktionen
export const getCart = () => {
    const cart = localStorage.getItem('cart');
    return cart ? JSON.parse(cart) : [];
};

export const getCartItemQuantity = (productId) => {
    const cart = getCart();
    const cartItem = cart.find(item => item.id === productId);
    return cartItem ? cartItem.quantity : 0;
};


export const addToCart = (product) => {
    const cart = getCart();
    const existingProductIndex = cart.findIndex(item => item.id === product.id);
    if (existingProductIndex !== -1) {
        // Produkt bereits im Warenkorb vorhanden, erhöhe die Menge
        cart[existingProductIndex].quantity += 1;
    } else {
        // Produkt dem Warenkorb hinzufügen
        cart.push({ ...product, quantity: 1 });
    }
    localStorage.setItem('cart', JSON.stringify(cart));
    // Event auslösen, um andere Komponenten über die Aktualisierung des Warenkorbs zu informieren
    window.dispatchEvent(new Event('cartUpdated'));
};

export const removeFromCart = (productId) => {
    const cart = getCart();
    const updatedCart = cart.filter(item => item.id !== productId);
    localStorage.setItem('cart', JSON.stringify(updatedCart));
    window.dispatchEvent(new Event('cartUpdated'));
};

export const clearCart = () => {
    localStorage.removeItem('cart');
    window.dispatchEvent(new Event('cartUpdated'));
};