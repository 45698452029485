import "./impressumPage.css"

function ImpressumPage() {

       return (
           <div class="zentriert" style={{ margin: '2em' }}>
                <h2>Impressum</h2>

                <p>Max Mustermann</p>
                <p>Musterweg</p>
                <p>12345 Stadt</p>

                <h3>Vertreten durch:</h3>
                <p>Max Mustermann</p>

                <h3>Kontakt:</h3>
                <p>Telefon: </p>
                <p>Email: </p>

           </div>
       );
}

export default ImpressumPage;