import React, { useEffect, useState } from 'react';
import './Checkout.css';
import { getCart, removeFromCart, clearCart } from '../../services/cookieCart';
import payMethod from '../../services/classes/payMethod';
import { Button, Col, Row } from "react-bootstrap";
import { baseUri } from "../../services/httpRequestor";
import {toast} from "react-toastify";

//--------------------------------------------------------------------
// Zahlungsmethoden
const paymentMethods = [
    new payMethod(1, "Kreditkarte", "Kreditkarte", "Max Mustermann", "019248104210148", "481"),
    new payMethod(2, "PayPal", "PayPal", "Max Mustermann", "max.mustermann@icloud.com", "/")
];

function Checkout() {
    //--------------------------------------------------------------------
    // Warenkorb Zustände und Funktionen
    const [cartItems, setCartItems] = useState(getCart());

    const renderCartItems = () => {
        return cartItems.map(item => (
            <div key={item.id} className="cart-items">
                <div className="item-name"><strong>Produkt:</strong> {item.name} für <u>{item.price}€</u></div>
                <div className="item-details">
                    <div><strong>Anzahl:</strong> {item.quantity}</div>
                    <div><strong>Total:</strong> {(item.quantity * item.price).toFixed(2)}€</div>
                </div>
            </div>
        ));
    };

    const calculateTotalPrice = () => {
        let total = 0;
        cartItems.forEach(item => {
            total += item.price * item.quantity;
        });
        return total.toFixed(2);
    };

    //--------------------------------------------------------------------
    // Lieferadresse Zustände und Funktionen
    const [address, setAddress] = useState([]);

    async function getAddresses() {
        const token = localStorage.getItem('token');
        const response = await fetch(baseUri + '/user/addresses', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            }
        });

        if (response.status === 200) {
            const stream = await response.body.getReader().read();
            const text = new TextDecoder().decode(stream.value);
            const data = JSON.parse(text);
            setAddress(data);
            console.log(data)
        }
    }

    useEffect(() => {
        getAddresses();
    }, []);

    const [selectedAddress, setSelectedAddress] = useState(null);

    const handleAddressChange = (event) => {
        const selectedAddressId = parseInt(event.target.value);
        const selectedAddress = address.find(address => address.id === selectedAddressId);
        setSelectedAddress(selectedAddress);
    };

    const renderAddresses = () => {
        return address.map(a => (
                <option key={a.id} value={a.id}>
                    {a.lable} ({((a.poBox === "true") ? "Paketstation" + "(" + a.poNum + ")" : "Lieferadresse")}): {a.address.road} {a.address.houseNumber}, {a.address.zipCode}, {a.address.town}, {a.address.country}
                </option>
            )
        )
    }

    //--------------------------------------------------------------------
    // Zahlungsmethoden Zustände und Funktionen
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null); // <-- Hier war der Fehler

    const handlePaymentMethodChange = (event) => {
        const selectedMethodId = parseInt(event.target.value);
        const selectedMethod = paymentMethods.find(method => method.id === selectedMethodId);
        setSelectedPaymentMethod(selectedMethod);
    };


    const renderPaymentMethods = () => {
        return paymentMethods.map(method => (
            <option key={method.id} value={method.id}>
                {method.name} - {method.type} ({method.getHiddenInf1()}, {method.getHiddenInf2()})
            </option>
        ));
    };

    //--------------------------------------------------------------------
    // Standardmäßig das erste Element in den Dropdown-Listen auswählen
    useEffect(() => {
        if (address.length > 0) {
            setSelectedAddress(address[0]);
        }
        if (paymentMethods.length > 0) {
            setSelectedPaymentMethod(paymentMethods[0]);
        }
    }, [address, paymentMethods]);

    //--------------------------------------------------------------------
    // Ladeindikator und Fehlermeldung Zustände und Funktionen
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getAddresses().then(() => setLoading(false));
    }, []);

    const [error, setError] = useState('');

    //--------------------------------------------------------------------
    // Bestätigung der Bestellung
    const orderConfirm = async () => {
        if (!selectedPaymentMethod || !selectedAddress) {
            toast.error('Bitte wählen Sie eine Zahlungsmethode und eine Lieferadresse aus.');
            return;
        }

        const token = localStorage.getItem('token');

        if (token && selectedPaymentMethod && selectedAddress) {

            let body = generateOrderJSON();
            console.log(body)

            const requestOptionsCheckout = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + token,
                },
                body: JSON.stringify(body)
            };
            console.log(requestOptionsCheckout);
            const responseCheckout = await fetch(baseUri.concat("/order/create"), requestOptionsCheckout);
            console.log(responseCheckout);
            if (responseCheckout.status === 200){
                console.log(responseCheckout);
                localStorage.setItem('orderStatus', "orderSuccess");
                window.location.href = "/orderSuccess";
            } else if (responseCheckout === 409){
                localStorage.setItem('orderStatus', "orderFail");
                window.location.href = "/orderSuccess";
            } else {
                toast.error("Fehler bei Übermittlung der Bestellung.")
            }
        } else {
            console.error("Fehler beim Bestätigen der Bestellung: Ungültige Zahlungsmethode oder Adresse");
        }
    };

    const generateOrderJSON = () => {
        const products = cartItems.map(item => ({
            productId: item.id,
            amount: item.quantity
        }));

        const deliveryAddressLinkId = selectedAddress.id;

        return {
            products,
            deliveryAddressLinkId
        };
    };

    //------------------------Login required - Handling------------------------------
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
        localStorage.setItem("loginRequired", "Bitte loggen Sie sich ein.");
        window.location.href = "/login";
        return false;
    }
    //-------------------------------------------------------------------------------


    //--------------------------------------------------------------------
    // Rückgabe des JSX
    return (
        <div style={{margin: '2em'}}>
            <div className="Heading">
                <h1 style={{margin: '2em'}}>Kasse</h1>
            </div>
            {loading ? (
                <div style={{textAlign: "center"}}>Loading...</div>
            ) : (
                <div className="checkout-section">
                    <div className="cart-items-container">
                        <h2>Deine Produkte:</h2>
                        <div>
                            {cartItems.length > 0 ? renderCartItems() : <p>Der Warenkorb ist leer.</p>}
                        </div>
                    </div>
                    <a href='/cart'>Warenkorb bearbeiten</a>
                    <br/><br/>
                    <div className="payment-method-container">
                        <h2>Zahlungsmethode wählen:</h2>
                        <select className="payment-method-select" onChange={handlePaymentMethodChange}>
                            {renderPaymentMethods()}
                        </select>
                    </div>
                    <div className="address-container">
                        <h2>Lieferadresse wählen:</h2>
                        <select className="address-select" onChange={handleAddressChange}>
                            {renderAddresses()}
                        </select>
                    </div>
                    <br/><br/>
                    <div className="total-price-container">
                        <h2>Gesamtpreis: {calculateTotalPrice()}€</h2>
                    </div>
                    <br/><br/>
                    <Row className="justify-content-between">
                        <Col>
                            <Button variant="danger" href='/cart' className="me-3 w-100">Zurück</Button>
                        </Col>
                        <Col>
                            <Button
                                variant="success"
                                className="w-100"
                                disabled={cartItems.length === 0}
                                onClick={() => orderConfirm()}>
                                Kaufen
                            </Button>
                        </Col>
                    </Row>
                </div>
            )}
            {error && <div className="error-message">{error}</div>}
        </div>
    );
}

export default Checkout;
