import './MapPage.css'
import React, {useEffect, useState} from "react";
import L from 'leaflet';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import HelmetExport from "react-helmet";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {baseUri} from "../../../services/httpRequestor";



function MapPage(){
    const [location, setLocation] = useState([48.6841594,10.1501465]);
    const [vendors, setVendors] = useState([]);

    L.Marker.prototype.options.icon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow
    });
    
    function success(pos) {
        const latitude = pos.coords.latitude;
        const longitude = pos.coords.longitude;
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        setLocation([latitude, longitude])
    }

    function error() {
        console.log("Unable to retrieve your location");
    }
    
    async function getVendors(){
        const response = await fetch(baseUri + '/vendor/list');
        const body = await response.body.getReader().read();
        const vendors = JSON.parse(new TextDecoder().decode(body.value));
        setVendors(vendors);
    }
    
    useEffect( () => {
        getVendors();
    }, []);
    
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error)
    } else {
        console.log("Geolocation not supported");
    }
    
    return (
        <div>
            <MapContainer id="map" center={location} zoom={17} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker id="userPos" position={location}>
                    <Popup>
                        You are Here
                    </Popup>
                </Marker>
                {vendors.map(vendor => (
                    <Marker position={[vendor.address.latitude, vendor.address.longitude]}>
                        <Popup>
                            <a href={'/marketplace/' + vendor.name}>{vendor.name}</a>
                        </Popup>
                    </Marker>
                ))}
                
            </MapContainer>
            <HelmetExport>
                <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
                      integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
                      crossOrigin=""/>
                <script src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
                        integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
                        crossOrigin=""></script>
            </HelmetExport>
        </div>
    );
}

export default MapPage;