import './LandingPage.css'

import MapPage from '../components/MapPage/MapPage.js'
import DsgvoPopup from '../components/DsgvoPopup/DsgvoPopup.js';

function LandingPage() {

    return (
        <div className="landingPage">

            <MapPage />

            {showPopup()}
        
        </div>
    );
}

function showPopup() {
    //localStorage.setItem('dsgvoAccept', "false"); //Nur zum testen
    if ( localStorage.getItem('dsgvoAccept') === "true") {
        return (<></>); 
    } else {
        return (<DsgvoPopup />);
    }
    
}
    
export default LandingPage;