import React, {useEffect, useState} from 'react';
import Product from '../../services/classes/Product';
import Vendor from '../../services/classes/Vendor';
import Address from '../../services/classes/Address';
import {baseUri} from "../../services/httpRequestor";
import {addToCart, getCartItemQuantity} from "../../services/cookieCart";
import {toast} from "react-toastify";

function ProductListing() {
    const [vendors, setVendors] = useState([]);
    const [products, setProducts] = useState([]);

    const getVendorsAndProducts = async () => {
        const response = await fetch(baseUri + '/vendor/list');
        const body = await response.body.getReader().read();
        const data = JSON.parse(new TextDecoder().decode(body.value));
        console.log(data);
        setVendors(data);
        
        const allProducts = []
        for (let i = 0; i < data.length; i++) {
            allProducts.push(...data[i].products);
        }
       
        setProducts(allProducts);

        console.log(allProducts);
    }

    useEffect(() => {
        getVendorsAndProducts();
    }, [])

    //--------------------------------------------------------------------
    // Warenkorbhandling
    const handleAddToCart = (product) => {
        if (product.stock <= getCartItemQuantity(product.id)){
            console.log(product)
            console.log(getCartItemQuantity(product.id))
            toast.error("Es befinden sich zu viele Produkt im Warenkorb!");
            return false;
        } else {
            addToCart(product);
            toast.info(product.name + " (" + getCartItemQuantity(product.id)+ ") " + "zu Warenkorb hinzugefügt!")
            return true;
        }
    };
    
    //--------------------------------------------------------------------
    // State für ausgewählte Händler
    const [selectedVendors, setSelectedVendors] = useState([]);
    // State für den Suchbegriff für Produkte
    const [productSearchTerm, setProductSearchTerm] = useState('');
    // State für den Suchbegriff für Verkäufer
    const [vendorSearchTerm, setVendorSearchTerm] = useState('');

    // Handler-Funktion für die Auswahl eines Händlers über die Checkbox
    const handleCheckboxSelect = (vendorName) => {
        if (selectedVendors.includes(vendorName)) {
            setSelectedVendors(selectedVendors.filter(name => name !== vendorName));
        } else {
            setSelectedVendors([...selectedVendors, vendorName]);
        }
    };

    // Handler-Funktion für die Auswahl eines Händlers über den Namen
    const handleNameSelect = (vendorName) => {
        setSelectedVendors([vendorName]);
    };

    // Filtern der Produkte basierend auf den ausgewählten Händlern und dem Suchbegriff
    const filteredProducts = products.filter(product => {
        return (
            product.name.toLowerCase().includes(productSearchTerm.toLowerCase()) ||
            product.description.toLowerCase().includes(productSearchTerm.toLowerCase())
        );
    }).filter(product => {
        const vendor = vendors.find(vendor => vendor.products.includes(product));
        return (
            selectedVendors.length === 0 ||
            (vendor && selectedVendors.includes(vendor.name))
        );
    });

    // Filtern der Verkäufer basierend auf dem Suchbegriff
    const filteredVendors = vendors.filter(vendor => {
        return vendor.name.toLowerCase().includes(vendorSearchTerm.toLowerCase());
    });

    //--------------------------------------------------------------------
    // Vendors
    const vendorElements = filteredVendors.map(vendor => (
        <tr key={vendor.name}>
            <td style={{width: '1%'}}><input type="checkbox" value={vendor.name}
                                             onChange={() => handleCheckboxSelect(vendor.name)}
                                             checked={selectedVendors.includes(vendor.name)}/></td>
            <td onClick={() => handleNameSelect(vendor.name)}
                style={{textDecoration: 'underline', cursor: 'pointer'}}>{vendor.name}</td>
            <td>{vendor.address.road} {vendor.address.houseNumber}, {vendor.address.town} {vendor.address.zipCode}</td>
            <td><a href={`/marketplace/${vendor.name}`}>hier&#11008;</a></td>
        </tr>
    ));


    //--------------------------------------------------------------------
    // Products
    const productElements = filteredProducts.map(product => (
        <tr key={product.id}>
            <td>{product.name}: {product.description}</td>
            <td>{product.price} €</td>
            <td>
                <a href="#" onClick={() => handleAddToCart(product)}
                   style={{textDecoration: 'underline', color: '#0000EE', cursor: 'pointer'}}>
                    +
                </a>
            </td>
        </tr>
    ));

    //--------------------------------------------------------------------
    return (
        <div style={{margin: '2em'}}>
        <div className="Heading">
                <h1 style={{ margin: '2em' }}>Marktplatz</h1>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6">
                        <h2>Verkäufer</h2>
                        <input
                            type="text"
                            placeholder="Verkäufer suchen..."
                            value={vendorSearchTerm}
                            onChange={(e) => setVendorSearchTerm(e.target.value)}
                            className="form-control"
                            style={{ marginBottom: '1em' }}
                        />
                        <table className="table table-striped">
                            <thead className="thead-light">
                            <tr>
                                <th></th>
                                <th>Verkäufer</th>
                                <th>Adresse</th>
                                <th>Händlerseite</th>
                            </tr>
                            </thead>
                            <tbody>
                            {vendorElements}
                            </tbody>
                        </table>
                    </div>
                    <div className="col-lg-6">
                        <h2>Produkte</h2>
                        <input
                            type="text"
                            placeholder="Produkt suchen..."
                            value={productSearchTerm}
                            onChange={(e) => setProductSearchTerm(e.target.value)}
                            className="form-control"
                            style={{ marginBottom: '1em' }}
                        />
                        <table className="table table-striped">
                            <thead className="thead-light">
                            <tr>
                                <th>Beschreibung</th>
                                <th>Preis</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {productElements}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductListing;
