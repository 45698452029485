import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { baseUri } from "../../services/httpRequestor";

function VendorTasks() {

    const [tasks, setTasks] = useState([]);
    const [vendorInfo, setVendorInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [filterUser, setFilterUser] = useState(""); // Zustand für den gefilterten Benutzernamen
    const token = localStorage.getItem('token');

    //------------------------GetTasks functions-----------------------------
    const getTasks = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": "Bearer " + token,
            },
        }
        try {
            const response = await fetch(baseUri + '/vendor/getInternals', requestOptions);
            const status = response.status;
            if (status === 200) {
                const data = await response.json();
                setVendorInfo(data);
                setTasks(data.orders);
                setLoading(false);
                console.log(data);
            } else if (status === 404) {
                localStorage.setItem('noVendor', 'Der Account besitzt kein zugehöriges Geschäft.')
                window.location.href = '/profile'
            } else if (status === 401) {
                toast.error("Unberechtigter Zugriff.");
            } else {
                toast.error("Fehler beim Laden der Aufträge.");
            }
        } catch (error) {
            console.error("Fehler beim Abrufen der Aufgaben:", error);
            toast.error("Fehler beim Laden der Aufträge.");
        }
    }

    useEffect(() => {
        getTasks();
    }, []);

    //------------------------Login required - Handling------------------------------
    if (window.localStorage.getItem("isLoggedIn") !== "true") {
        localStorage.setItem("loginRequired", "Bitte loggen Sie sich ein.");
        window.location.href = "/login";
        return false;
    }

    //------------------------Render Tasks functions-----------------------------

    function RenderTaskCard(task) {
        const product = vendorInfo.products.find(prod => prod.id === task.productId);
        const productName = product ? product.name : "Produkt nicht gefunden";
        const productPrice = product ? product.price : "N/A";
        const totalPrice = product ? product.price * task.amount : "N/A";

        return (
            <div className="container">
                <div id="orderCard" className="card customCardCSS mb-3">
                    <div className="card-body">
                        <h5 className="card-title">
                            Bestellung für {task.userEmail}
                            {filterUser !== task.userEmail && (
                                <><br/><small><a style={{color: 'blue', textDecoration: 'underline', cursor: 'pointer'}}
                                                 onClick={() => setFilterUser(task.userEmail)}>Aufträge nach Nutzer
                                    filtern</a></small></>
                            )}
                        </h5>
                        <p className="card-text">Lieferadresse: {task.deliverTo? (<>{task.deliverTo.road} {task.deliverTo.houseNumber}, {task.deliverTo.town}, {task.deliverTo.zipCode}, {task.deliverTo.country}</>) : (<>N/A</>)}</p>
                        <p className="card-text">Bestelldatum: {new Date(task.orderDate).toLocaleString()}</p>
                        <p className="card-text">Produkt: {productName}, Einzelpreis: {productPrice}</p>
                        <p className="card-text">Anzahl: {task.amount}</p>
                        <p className="card-text">Gesamtpreis: {totalPrice}</p>
                    </div>
                </div>
            </div>
        );
    }

    const filteredTasks = filterUser ? tasks.filter(task => task.userEmail === filterUser) : tasks;
    const taskCards = filteredTasks.map(task => RenderTaskCard(task));

    return (
        <div style={{ margin: '2em' }}>
            <div className="Heading">
                <h1 style={{ margin: '2em' }}>Meine Aufträge</h1>
            </div>
            {filterUser && (
                <center><button className="btn btn-danger" onClick={() => setFilterUser("")}>Filter entfernen</button></center>
            )}
            {loading ? (<p>Loading...</p>) : (taskCards)}
        </div>
    )

}

export default VendorTasks;
