// VendorMiniMap.js
import './VendorMiniMap.css'

import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";

function VendorMiniMap({ vendor }) {

    const position = [vendor.address.latitude, vendor.address.longitude];

    return (
        <div>
        <MapContainer center={position} zoom={13} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={position}>
                <Popup>
                    {vendor.name}
                </Popup>
            </Marker>
        </MapContainer>
        </div>

    );
}

export default VendorMiniMap;
